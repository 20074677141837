import PlaybackSectionConfig from './PlaybackSectionConfig';

export default class PlaybackConfig {
  live: PlaybackSectionConfig;
  vod: PlaybackSectionConfig;

  constructor(obj?: PlaybackConfig) {
    this.live = new PlaybackSectionConfig(obj?.live);
    this.vod = new PlaybackSectionConfig(obj?.vod);
  }
}
