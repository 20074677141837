export interface ProbeRequest {
  url: string;
  returnResponse: boolean;
}

function fromUrls(probeUrls?: string[]): ProbeRequest[] {
  if (!probeUrls) {
    return [];
  }
  return probeUrls.map(url => ({ url, returnResponse: false }));
}

export function getProbeRequests({
  probeRequests,
  probeUrls
}: {
  probeRequests?: ProbeRequest[];
  probeUrls?: string[];
}): ProbeRequest[] {
  return probeRequests ? probeRequests : fromUrls(probeUrls);
}
