import logger, { Level } from '../log';
import { ReporterAppender } from '../log';
import ContentConfig, { MaybeContentConfig } from '../config/ContentConfig';

// Only configure the logger for the Reporter
export default function configureReportLogging(
  clear?: boolean,
  conf?: MaybeContentConfig
): void {
  const config = ContentConfig.getInstance();
  if (conf) {
    config.merge(conf);
  }
  if (clear) {
    logger.setLevel(Level.OFF, ReporterAppender.NAME); // Clear out the logging state
  }
  logger.setLevel(config.report.logLevel, ReporterAppender.NAME);
  Object.entries(config.report.log).forEach(([category, level]) => {
    logger.setLevel(level as Level, ReporterAppender.NAME, category);
  });
  // Also configure console level.
  const consoleLevel =
    config.report.consoleLevel || logger.appenders.CONSOLE.level.label;
  if (config.report.console && clear) {
    logger.setLevel(Level.OFF, 'CONSOLE');
  }
  logger.setConsoleLevel(consoleLevel);
  if (config.report.consoleType) {
    logger.setConsoleType(config.report.consoleType);
  }
  if (config.report.console) {
    Object.entries(config.report.console).forEach(([category, level]) => {
      logger.setConsoleLevel(level as Level, category);
    });
  }
}
