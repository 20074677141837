import { Level } from '../log';
import SDKConfig from '../common/SDKConfig';
import ContentConfig, { MaybeContentConfig } from '../config/ContentConfig';
import getPlugin from '../bootloader/getPlugin';
import { DeliveryMode } from './constants';
import { ApiLogger } from '../log/Logger';
import { Plugin, KollectiveGlobalApi } from '../common/interfaces';
import {
  IApi,
  KollectiveBaseLoadOptions,
  PlayerAPI,
  ITechApi
} from '@kollective-frontend/ksdk-api';
import { TechApi } from '../bootloader/tech';

export interface LoadOpts extends KollectiveBaseLoadOptions {
  contentToken?: string;
  jitc?: string;
  integratorToken?: string;
  config?: MaybeContentConfig;
  disabled?: boolean;
}

export interface MSPlugin extends Plugin {
  setupDone: () => false | { kollectivePlatform: DeliveryMode };
}

export interface PluginConstructor {
  new (
    playerType: string,
    player: PlayerAPI,
    playerOptions: unknown | undefined,
    tech: TechApi
  ): Plugin;
}

export interface MSPluginConstructor {
  new (player: unknown, playerOptions: unknown, tech: TechApi): MSPlugin;
}

export interface IApiInternal extends IApi<Plugin> {
  clearSessionData: (category?: string) => void;
  log?: ApiLogger;
  sdkConfig: SDKConfig;
  setConsoleLevel: (level: Level, category?: string) => string;
  setConsoleType: (type: string) => string;
  setDisabled: () => void;
  setPlugin: (instance: Plugin) => void;
  setSessionLogging: (category: string, on?: boolean, save?: boolean) => string;
  tech: ITechApi;
}

export default class Api implements ApiLogger, IApiInternal {
  sdkConfig: SDKConfig;
  log?: ApiLogger;
  tech: ITechApi;
  private pluginDisabled: boolean;

  constructor(sdkConfig?: SDKConfig) {
    this.sdkConfig = sdkConfig || new SDKConfig({ name: 'ksdk' }, 20000);
    this.pluginDisabled = false;
    this.tech = {
      get: (name: string) =>
        (window.ksdk as KollectiveGlobalApi)._internal.tech.get(name)
    };
  }

  setDisabled(): void {
    this.pluginDisabled = true;
  }

  isDisabled(): boolean {
    return this.pluginDisabled;
  }

  setPlugin(instance: Plugin): void {
    const internal = (window.ksdk as KollectiveGlobalApi)._internal;
    if (internal && internal.plugin) {
      // We are resetting the plugin, cleanup old state.
      internal.plugin.stop();
    }
    internal.plugin = instance;
    if (instance && instance.sdkConfig) {
      // reset the sdk config based on the passed instance
      this.sdkConfig = instance.sdkConfig as SDKConfig;
    }
    // For backwards compatibility
    this.log = instance.log;
  }

  getConfig(): ContentConfig | undefined {
    const plugin = getPlugin();
    if (plugin) {
      return plugin.getConfig() as ContentConfig;
    } else {
      return undefined;
    }
  }

  setConsoleLevel(level: Level, category?: string): string {
    if (this.log) {
      return this.log.setConsoleLevel(level, category);
    }
    return '';
  }

  setConsoleType(type: string): string {
    if (this.log) {
      return this.log.setConsoleType(type);
    }
    return '';
  }

  commit(): string | undefined {
    return this.sdkConfig.gitCommit;
  }

  setSessionLogging(category: string, on?: boolean, save?: boolean): string {
    if (this.log && typeof this.log.setSessionLogging === 'function') {
      return this.log.setSessionLogging(category, on, save);
    }
    return 'No logger set, unable to set session logging';
  }

  clearSessionData(category?: string): void {
    if (this.log) {
      this.log.clearSessionData(category);
    }
  }

  version(): string | undefined {
    return this.sdkConfig.version;
  }

  integration(): { name: string } {
    return this.sdkConfig.integration;
  }
}
