export interface MaybeCacheConfig {
  type?: string;
  maxBytes?: number;
}

export default class CacheConfig {
  type: string;
  maxBytes: number;

  constructor(obj?: MaybeCacheConfig) {
    const copy = { type: 'Map', maxBytes: 256000000, ...obj };
    this.type = copy.type || 'Map';
    this.maxBytes = copy.maxBytes;
  }
}
