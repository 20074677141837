import ContentConfig from '../config/ContentConfig';
import getSdkUri, { GetSdkUriArgs, GetSdkUriResponse } from '../api/getSdkUri';
import {
  DiagnosticCode,
  SendDiagnosticArgs
} from '../api/diagnostics/constants';
import { sendBootstrapDiagnostic } from '../api/diagnostics/sendDiagnostic';
const MAX_RETRIES = 2;

function handleSdkVersion(
  resp: GetSdkUriResponse | undefined
): Promise<GetSdkUriResponse> {
  if (!resp) {
    return Promise.reject();
  }
  return Promise.resolve(resp);
}

export interface GetBaseConfigArgs extends GetSdkUriArgs {
  contentId?: string; // Where is this supposed to go...
  overrideUrl?: string;
}

const getBaseConfigFromBackend = async (
  input: GetBaseConfigArgs,
  retry = 0
): Promise<GetSdkUriResponse> => {
  const { overrideUrl } = input;
  const result: GetSdkUriResponse = await getSdkUri(input)
    .then(handleSdkVersion)
    .catch(async () => {
      await sendSdkLoadFailureDiagnostic(input);
      if (retry < MAX_RETRIES) {
        return getBaseConfigFromBackend(input, retry + 1);
      }
      return { sdkUrl: overrideUrl };
    });
  return { ...result, sdkUrl: overrideUrl || result.sdkUrl };
};

export const sendSdkLoadFailureDiagnostic = async (
  input: GetBaseConfigArgs
): Promise<void> => {
  const code = DiagnosticCode.FAILED_FETCH_SDK;
  const args: Record<string, unknown> = { ...input, code };
  if (!args.statsHost && !args.envHostSuffix) {
    const statsHost = ContentConfig.getInstance().sth;
    if (statsHost) {
      args.statsHost = statsHost;
    } else if (input.apiHost) {
      args.envHostSuffix = getSuffix(input.apiHost);
    }
  }
  sendBootstrapDiagnostic(args as unknown as SendDiagnosticArgs);
};

const getSuffix = (input: string): string => {
  return input.substring(input.indexOf('.') + 1);
};

export default getBaseConfigFromBackend;
