import BaseLogger from './Logger';
import { safeGet, safeSave, getStorage } from '../util/storage';

/**
 * Log data to session storage, for later debugging.
 */
type RsD = Record<string, SessionLogger>;
type Rsb = Record<string, boolean>;
const BASE_KEY = 'ksdk::log::data';

interface DataKeys {
  data: Record<string, boolean>;
  key: string;
  storage: Storage;
}

interface KeyBlock {
  category: string;
  section?: string;
  tag?: string;
}

interface StorageDoc {
  msg?: Record<string, unknown>;
  section?: string;
  tag?: string;
  plan?: AlgorithmPlan;
}
export enum AlgorithmPlan {
  BOTH = 'both',
  CATEGORY = 'category',
  SECTION = 'section',
  EITHER = 'either'
}

interface SetSessionLogArgs {
  on?: boolean;
  section?: string;
  getFile?: boolean;
}

export default class SessionLogger {
  readonly category: string;
  readonly docs: RsD;
  private readonly logger: BaseLogger;
  private readonly storage: Storage;
  private readonly local: Storage;

  constructor(category: string, logger: BaseLogger, docs: RsD) {
    this.category = category || '';
    this.logger = logger;
    this.docs = docs;
    this.storage = getStorage({ which: 'sessionStorage' });
    this.local = getStorage({ which: 'localStorage' });
  }

  msg(input: StorageDoc): void {
    const keys = this.determineIfLoggable(input);
    if (!keys) {
      return;
    }
    if (keys.section) {
      this.storeSessionData(keys.section, input.msg);
    } else {
      this.storeSessionData(keys.category, input.msg);
    }
    if (keys.tag) {
      this.storeSessionData(keys.tag, input.msg);
    }
  }

  isLoggable(key: string): boolean {
    return (
      this.storage.getItem(`${key}|log`) == 'true' ||
      this.local.getItem(`${key}|log`) == 'true'
    );
  }

  /**
   *
   */
  determineIfLoggable(input: StorageDoc): KeyBlock | undefined {
    const { msg, section, tag } = input;
    const plan = input.plan || AlgorithmPlan.EITHER;
    const catKey = this.getKey({});
    const secKey = this.getKey({ section });
    const tagKey = this.getKey({ tag, msg });
    const cat = this.isLoggable(catKey);
    const sec = this.isLoggable(secKey);
    const hasTag = this.isLoggable(tagKey);
    if (
      (plan == AlgorithmPlan.BOTH && cat && (sec || hasTag)) ||
      (plan == AlgorithmPlan.EITHER && (cat || sec || hasTag)) ||
      (plan == AlgorithmPlan.CATEGORY && cat) ||
      (plan == AlgorithmPlan.SECTION && (sec || hasTag))
    ) {
      const result: KeyBlock = {
        category: catKey,
        section: secKey,
        tag: this.getKey(input)
      };
      if (result.tag == result.section || result.tag == result.category) {
        delete result.tag;
      }
      if (result.category == result.section) {
        delete result.section;
      }
      return result;
    }
  }

  setSessionLogging(t?: SetSessionLogArgs): string {
    const on = (t && t.on) || false;
    if (t && t.getFile) {
      return this.getFile(this.category as string);
    }
    const key = this.getSessionKey(`${this.category}|log`);
    if (on) {
      this.storage.setItem(key, 'true');
    } else {
      this.storage.removeItem(key);
    }
    return `Set session logging for ${this.category} to ${on ? 'on' : 'off'}`;
  }

  getFile(category: string): string {
    const data = this.safeGet(
      `${BASE_KEY}::${category}`,
      [],
      'log to file'
    ) as unknown[];
    const str = JSON.stringify(data);
    const blob = new Blob([str], { type: 'octet/stream' });
    const burl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', burl);
    link.setAttribute('download', `${category}.json`);
    link.click();
    return `Downloading ${category}.json`;
  }

  getSessionKey(category?: string, section?: string): string {
    section = section ? `-${section}` : '';
    return `${BASE_KEY}::${category || ''}${section}`;
  }

  getKey(input: StorageDoc): string {
    const { section, msg, tag } = input;
    const prefix = section ? `-${section}` : '';
    const infix = msg && tag && msg[tag] ? `::${msg[tag]}` : '';
    return `${BASE_KEY}::${this.category}${prefix}${infix}`;
  }

  getSessionDataKeys(category: string): DataKeys {
    const storage = this.storage;
    const key = `${this.getSessionKey(category)}::_keys`;
    const data = this.safeGet(key, {}, 'store session keys', storage) as Rsb;
    return { data, key, storage };
  }

  storeSessionData(key: string, msg: unknown): void {
    const data = this.safeGet(key, [], `parse data for ${key}`) as unknown[];
    data.push(msg);
    this.storeSessionDataKeys(key, [key, `${key}.count`]);
    this.storage.setItem(`${key}.count`, `${data.length}`);
    safeSave({ data, key, storage: this.storage, log: this.logger });
  }

  safeGet<T>(
    key: string,
    defaultValue: T,
    error: string,
    storage: Storage = this.storage
  ): T {
    return safeGet({
      default: defaultValue,
      error: ` data for key ${key}: ${error}`,
      log: this.logger,
      key,
      storage
    });
  }

  storeSessionDataKeys(baseKey: string, keys: string[]): void {
    const { key, data, storage } = this.getSessionDataKeys(this.category);
    let needsStorage = false;
    [...keys, key].forEach(value => {
      needsStorage = needsStorage || !data[value];
      data[value] = true;
    });
    if (needsStorage) {
      storage.setItem(key, JSON.stringify(data));
    }
  }

  clearSessionData(category?: string): void {
    if (typeof category === 'string') {
      this._clearSessionData(category);
    } else {
      Object.keys(this.docs).forEach(c => this._clearSessionData(c));
    }
  }

  private _clearSessionData(category: string): void {
    const { key, data, storage } = this.getSessionDataKeys(category);
    storage.removeItem(key);
    Object.keys(data).forEach(i => this.storage.removeItem(i));
  }
}
