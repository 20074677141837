import logger from '../../log';
import { Base64 } from 'js-base64';

const log = logger.getLogger(__filename);

/**
 * @param input - the base64 string
 * @param name - the name to log if there is an error
 *
 * @returns json for the decoded string, or on failure, a basic json
 * object.
 */
export default function (input, name) {
  try {
    return JSON.parse(Base64.decode(input).replaceAll('\0', ''));
  } catch (e) {
    log.warn(`failed to parse ${name} input:`, input, e);
  }
  return {};
}
