import { Bitrates } from './interfaces';

export default class PlaybackSectionConfig implements Bitrates {
  priority: string;
  delay?: number;
  minVideoBitrate?: number;
  maxVideoBitrate?: number;
  minAudioBitrate?: number;
  maxAudioBitrate?: number;

  constructor(obj?: PlaybackSectionConfig) {
    this.priority = obj?.priority ?? '';
    this.delay = obj && obj.delay;
    this.minVideoBitrate = obj && obj.minVideoBitrate;
    this.maxVideoBitrate = obj && obj.maxVideoBitrate;
    this.minAudioBitrate = obj && obj.minAudioBitrate;
    this.maxAudioBitrate = obj && obj.maxAudioBitrate;
  }
}
