import { v4 as uuidv4 } from 'uuid';
import { getStorage } from './storage';

// section node/agent ids
export const NODE_KEY = 'ksdk::common::webrtc::nodeId';
export const AGENT_KEY = 'ksdk::common::webrtc::agentId';

export default function getAgentId(): string {
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
  const storage = getStorage({ which: 'localStorage' });
  const storedAgentId = storage.getItem(AGENT_KEY);
  if (storedAgentId) {
    return storedAgentId;
  }
  // Get agent keys that were stored in local storage under the node key.
  const stored = storage.getItem(NODE_KEY) || uuidv4();
  storage.setItem(AGENT_KEY, stored);
  return stored;
}
