import setVar from './util/setVar';

export const defaultConfig = {
  backoffStatusCodes: [429, 503],
  initBackoffInterval: 500,
  jitterFactor: 1.0,
  maxBackoffInterval: 50000,
  maxDuration: 120000,
  requestTimeout: 35000,
  retryCount: 3
};

const put = (
  name: keyof ContentSessionRetryConfig,
  def: ContentSessionRetryConfig,
  conf?: ContentSessionRetryConfig
) => {
  return setVar(def[name], conf && conf[name]);
};

export const newContentSessionRetryConfig = (
  conf?: ContentSessionRetryConfig
): ContentSessionRetryConfig => {
  return {
    backoffStatusCodes: put('backoffStatusCodes', defaultConfig, conf),
    initBackoffInterval: put('initBackoffInterval', defaultConfig, conf),
    jitterFactor: put('jitterFactor', defaultConfig, conf),
    maxBackoffInterval: put('maxBackoffInterval', defaultConfig, conf),
    maxDuration: put('maxDuration', defaultConfig, conf),
    requestTimeout: put('requestTimeout', defaultConfig, conf),
    retryCount: put('retryCount', defaultConfig, conf)
  };
};

export default interface ContentSessionRetryConfig {
  // List of status codes that are allowed for continuing retry process.
  // default: [429, 503]
  backoffStatusCodes: number[];
  // Initial backoff interval in ms.
  // default: 500
  initBackoffInterval: number;
  // Float value to apply to backoff interval to add jitter.
  // default: 1.0
  jitterFactor: number;
  // Max backoff interval
  // default: 50000
  maxBackoffInterval: number;
  // Max duration of the entire retry sequence in ms.
  // default: 120000 (2 minutes)
  maxDuration: number;
  // Timeout in ms for individual requests.
  // default: 35000
  requestTimeout: number;
  // Max retry count.
  // default: 3
  retryCount: number;
}
