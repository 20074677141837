export const ERROR_MSG_BASE =
  'Browser does not match minimum specifications' +
  ' to run. Kollective sdk is disabled. Errors:';

export const checkCapabilityType = (
  objName: string,
  name: string,
  item: unknown,
  type: string,
  arr: string[]
): void => {
  const obj = item as Record<string, unknown>;
  if (typeof obj[name] != type) {
    arr.push(`${objName} has no '${name}' of type '${type}'`);
  }
};

const validateBrowser = (): string | undefined => {
  const arr: string[] = [];
  const w = window as unknown as Record<string, unknown>;
  checkCapabilityType('window', 'fetch', w, 'function', arr);
  checkCapabilityType('window', 'AbortController', w, 'function', arr);
  checkCapabilityType('window', 'RTCPeerConnection', w, 'function', arr);
  checkCapabilityType('window', 'TextEncoder', w, 'function', arr);
  checkCapabilityType('Object', 'assign', Object, 'function', arr);
  if (arr.length > 0) {
    return `${ERROR_MSG_BASE} ${arr.join(', ')}`;
  }
  return;
};

export default validateBrowser;
