import BaseLogger, { Logger, LoggerWithType } from './Logger';
import ReporterAppender, { ReporterMessage } from './ReporterAppender';
import { Level, LogLevels } from './LoggerConstants';

const log = BaseLogger.create();

export default log;
export {
  Level,
  LogLevels,
  Logger,
  LoggerWithType,
  ReporterAppender,
  ReporterMessage
};
