import logger from '../log';
const log = logger.getLogger(__filename);

const PLATFORM_HEADER = 'X-Kollective-UA-Platform';
const PLATFORM_VERSION_HEADER = 'X-Kollective-UA-Platform-Version';
const FULL_VERSION_LIST_HEADER = 'X-Kollective-UA-Full-Version-List';

interface BrowserVersionBlock {
  brand: string;
  version: string;
}

interface HighEntropyUserAgentData {
  fullVersionList: BrowserVersionBlock[];
  platform: string;
  platformVersion: string;
}

interface UserAgentData {
  getHighEntropyValues: (m: string[]) => Promise<HighEntropyUserAgentData>;
}

interface UserAgentHeaders {
  [PLATFORM_HEADER]?: string;
  [PLATFORM_VERSION_HEADER]?: string;
  [FULL_VERSION_LIST_HEADER]?: string;
}

export const getUserAgentData = async (): Promise<HighEntropyUserAgentData> => {
  try {
    const n = window.navigator as unknown as Record<string, unknown>;
    const u = n.userAgentData as unknown as UserAgentData;
    if (!u) {
      return {} as HighEntropyUserAgentData;
    }
    const data = await u.getHighEntropyValues([
      'platform',
      'platformVersion',
      'fullVersionList'
    ]);
    log.trace('UserAgentData:', data);
    return data;
  } catch (e) {
    log.info('Failed to get UserAgentData', e);
    return {} as HighEntropyUserAgentData;
  }
};

export const brandsToString = (
  brands: BrowserVersionBlock[]
): string | undefined => {
  if (!brands) {
    return undefined;
  }
  return brands
    .reduce((acc, item) => {
      acc.push(`"${item.brand}";v="${item.version}"`);
      return acc;
    }, [] as string[])
    .join(', ');
};

const toWrappedString = (value?: string): string | undefined => {
  return typeof value == 'string' ? `"${value}"` : undefined;
};

const maybeAddHeader = (
  headers: UserAgentHeaders,
  key: string,
  value?: string
): void => {
  if (typeof value == 'undefined') {
    return;
  }
  (headers as Record<string, string>)[key] = value;
};

export const getUserAgentHeaders = async (
  headers: Record<string, string>
): Promise<void> => {
  const data = await getUserAgentData();
  const { platform, platformVersion: pv, fullVersionList: fvl } = data;
  maybeAddHeader(headers, PLATFORM_HEADER, toWrappedString(platform));
  maybeAddHeader(headers, PLATFORM_VERSION_HEADER, toWrappedString(pv));
  maybeAddHeader(headers, FULL_VERSION_LIST_HEADER, brandsToString(fvl));
};

// section userAgentHeaders
// Only exporting headersStorage for testing.
export const headersStorage: Record<string, string> = {};
export const mergeUserAgentHeaders = (hdrs: Record<string, string>): void => {
  Object.entries(headersStorage).forEach(([key, value]) => {
    hdrs[key] = value;
  });
};

export const setUserAgentHeaders = async (): Promise<void> => {
  await getUserAgentHeaders(headersStorage);
};
// end section
