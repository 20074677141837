import { Level } from '../log';
import setVar from './util/setVar';
import LegacyContentConfig from './LegacyContentConfig';
import MaximumReportConfig from './MaximumReportConfig';

const DEFAULT_REPORT_INTERVAL = 300000;
const DEFAULT_START_TIMEOUT = 300000;

export interface ReportConfigInterface extends ReportConfig {
  mbc?: number;
  svt?: boolean;
}

export default class ReportConfig {
  console: Record<string, Level>; // Set level for individual categories
  consoleLevel?: Level; // Log level for console [Default undefined]
  consoleType?: string; // Local only, set console logging options
  i: number; // Interval (in ms) to send reports [Default 5 mins]
  startTimeout: number; // Start Timeout for reports [Default 5 mins]
  log: Record<string, Level>; // Set level for individual categories or subcategories
  logLevel: Level; // General Log level [Default ERROR]
  max: MaximumReportConfig;

  constructor(obj?: ReportConfigInterface, config?: LegacyContentConfig) {
    this.i = setVar(
      DEFAULT_REPORT_INTERVAL,
      obj && obj.i,
      config && config.iar
    );
    this.startTimeout = setVar(DEFAULT_START_TIMEOUT, obj && obj.startTimeout);
    if (this.i <= 0) {
      this.i = DEFAULT_REPORT_INTERVAL;
    }
    this.console = obj?.console ?? {};
    this.consoleType = obj?.consoleType;
    this.consoleLevel = obj?.consoleLevel;
    this.log = obj?.log ?? {};
    this.logLevel = obj?.logLevel ?? Level.ERROR;
    this.max = new MaximumReportConfig(obj?.max, obj);
  }
}
