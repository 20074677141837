import { Logger } from '../log/Logger';
import GenericStorage from './GenericStorage';
import storageIsUnavailable from './storageIsUnavailable';
import { v4 as uuidv4 } from 'uuid';

export type StorageTypes = 'localStorage' | 'sessionStorage';

export const alternateStorage = {
  localStorage: new GenericStorage(),
  sessionStorage: new GenericStorage()
};

export interface GetStorageArgs {
  isSandboxed?: boolean;
  storage?: Storage;
  which?: StorageTypes;
}

export const getStorage = (args: GetStorageArgs): Storage => {
  if (typeof args.isSandboxed === 'undefined') {
    args.isSandboxed = storageIsUnavailable();
  }
  if (args.storage) {
    return args.storage;
  }
  if (args.isSandboxed) {
    if (args.which && alternateStorage[args.which]) {
      return alternateStorage[args.which];
    }
    return alternateStorage.sessionStorage;
  }
  if (args.which) {
    return window[args.which];
  }
  return window.sessionStorage;
};
// end section

// section: Generic storage get/save.
export interface SafeStorageArgs<T> {
  default?: T;
  error?: string;
  nonExistenceError?: string;
  key: string;
  log?: Logger;
  storage?: Storage; // defaults to window.sessionStorage
  which?: StorageTypes;
}

export interface SafeGetArgs<T> extends SafeStorageArgs<T> {
  default: T;
}

export function safeGet<T>(args: SafeGetArgs<T>): T {
  const storage = getStorage(args);
  const blob = storage.getItem(args.key);
  if (blob) {
    try {
      return JSON.parse(blob) as T;
    } catch (e) {
      const err = args.error ? `: ${args.error}` : ` data for key ${args.key}`;
      args.log?.warn(`Unable to get${err}`);
    }
  } else if (args.nonExistenceError) {
    args.log?.info(args.nonExistenceError);
  }
  return args.default;
}

export interface SafeSaveArgs<T> extends SafeStorageArgs<T> {
  data: T;
}

export function safeRemove(args: SafeStorageArgs<unknown>): void {
  const storage = getStorage(args);
  storage.removeItem(args.key);
}

export function safeSave<T>(args: SafeSaveArgs<T>): void {
  if (typeof args.data === 'undefined' && typeof args.default === 'undefined') {
    args.log?.info(`trying to save ${args.key} without data`);
    return;
  }
  const storage = getStorage(args);
  try {
    const str = JSON.stringify(args.data || args.default);
    storage.setItem(args.key, str);
  } catch (e) {
    const error = args.error ? `: ${args.error}` : ` data for key ${args.key}`;
    args.log?.warn(`Unable to save${error}`);
  }
}
// end section

// section getOpenerSection
/**
 * Get item from opener page if opener exists.
 * @param storageKey - key to lookup value
 * @returns value at storageKey
 */
export function getOpenerSession(storageKey: string): string | undefined {
  if (storageIsUnavailable()) {
    return undefined;
  }
  try {
    return window.opener && window.opener.sessionStorage.getItem(storageKey);
  } catch (e) {
    return undefined;
  }
}

/**
 * Get data object from the opener page if opener exists. This is here
 * specifically for the demo, it creates a new tab but clones the data
 * in the session storage, hence the term opener.
 * @param storageKey - key to lookup value
 * @returns json parsed sessionStorage value from storageKey
 */
export function getOpenerSessionData<T>(storageKey: string): T | null {
  if (storageIsUnavailable()) {
    return null;
  }
  try {
    const str = window.opener.sessionStorage.getItem(storageKey);
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}

// end section

// section node/agent ids
export const NETWORK_DATA_KEY = 'ksdk::common::locality::networkData';

export function getStoredGuidCheckingOpener(
  key: string,
  newGuid?: boolean
): string {
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
  const storage = getStorage({ which: 'sessionStorage' });
  if (!newGuid) {
    const stored = storage.getItem(key);
    const openerStored = getOpenerSession(key);
    if (stored && openerStored !== stored) {
      return stored;
    }
  }
  const guid = uuidv4();
  storage.setItem(key, guid);
  return guid;
}
// end section
