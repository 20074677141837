import NotFoundError from '../NotFoundError';

interface OperationConfigV0_1 {
  [key: string]: {
    uri: string;
    params: Record<string, string>;
  };
}

interface DiscoveryResponse<Op> {
  version: string;
  operations: Op;
}

/**
 * Returns the DiscoveryResponse or if failed (and endpoint is reachable), a NotFoundError.
 */
export async function getOperationV0_1(
  operation: string,
  envHostSuffix: string,
  apiHost?: string,
  requestInit?: RequestInit
): Promise<DiscoveryResponse<OperationConfigV0_1>> {
  apiHost = apiHost || `https://api.${envHostSuffix}`;
  const endpoint = new URL(
    `/api/discovery?operation=${operation}&version=0.1`,
    apiHost
  );
  const response = await fetch(endpoint.toString(), {
    ...requestInit,
    mode: 'cors'
  });
  if (response.ok) {
    const { version, operations } = await response.json();
    if (operations[operation]) {
      return { version, operations };
    } else {
      return Promise.reject(new NotFoundError());
    }
  }

  return Promise.reject(new NotFoundError());
}
