export enum DiagnosticCode {
  // 20XX -- Kollective Platform
  FAILED_FETCH_SDK = 2000,
  FAILED_LOAD_PEERING_KERNEL = 2001,
  ERROR_SENDING_REPORT = 2002,
  // 21XX -- Integration Related
  // 2100 - 2149 -- Common integration sdk failures
  // 2150+ is integration side data related.
  FAILED_EXTRACT_O365 = 2151,
  // 22XX -- Network Related
  WEBSOCKET_CONNECT_ISSUE = 2233
}

export const UNKNOWN_CONNECTION_FAILURE = 'unknown websocket failure';

// Diagnostic sent to stats:
// NOTE: Differs in that we don't send RUNTIME diagnostics so agentId and
// tenantId are included in the base ServiceDiagnostic interface
export interface ServiceDiagnostic {
  agentId: string;
  code: DiagnosticCode;
  reporter: 'SDK';
  reporterId: string;
  reporterVersion: string;
  tenantId: string;
  timestamp: number;
  type: ServiceDiagnosticType;
}

export interface ServiceSessionDiagnostic extends ServiceDiagnostic {
  app: string;
  clusterId?: string;
  contentId: string;
  data?: Record<string, unknown>;
  localities: string[];
  message: string;
  nodeId: string;
  sessionId: string;
  type: 'SESSION';
}

export interface ServiceBootstrapDiagnostic extends ServiceDiagnostic {
  type: 'BOOTSTRAP';
}

export type Diagnostic = ServiceSessionDiagnostic | ServiceBootstrapDiagnostic;

export type ServiceDiagnosticType = 'BOOTSTRAP' | 'SESSION';
export type UrlDiagnosticType = 'bootstrap' | 'session';
export type DiagnosticType = UrlDiagnosticType | ServiceDiagnosticType;

export interface SendDiagnosticArgs {
  // Data
  agentId?: string;
  code: DiagnosticCode;
  reporterId?: string;
  tenantId: string;
  timestamp?: number;

  // Information to be able to send.
  envHostSuffix?: string; // One of envHostSuffix or statsHost needs to be set
  statsHost?: string;

  // For Headers
  contentId?: string;
  playGuid?: string;
  token?: string;
}

export interface SendSessionDiagnosticArgs extends SendDiagnosticArgs {
  app: string;
  clusterId: string;
  contentId: string;
  data?: Record<string, unknown>;
  localities: string[];
  message: string;
  nodeId: string;
  sessionId: string;
  token: string;
}

export interface GetDiagnosticUrlArgs {
  type: DiagnosticType;
  code: DiagnosticCode;
  statsHost?: string;
  envHostSuffix?: string;
}
