import { Handler, MessageTemplates } from '@kollective-frontend/ksdk-api';
import { Subject, Subscription, SchedulerLike } from 'rxjs';
import detemplate from '../util/detemplate';
import logger from '../log';
import { DeprecatedEvents } from '../public/events';
import {
  Events,
  KsdkDispatchEvent,
  ReplacementEvents,
  allEvents
} from '../events';
import { Emitter, EventData } from './interfaces';
import { subscribeOn } from 'rxjs/operators';

const log = logger.getLogger(__filename);

export const warnIfDeprecatedEvent = (event: Events): void => {
  if (Object.values(DeprecatedEvents).find(k => k == event)) {
    const msg = detemplate(MessageTemplates.DEPRECATION_WARNING, {
      old: event,
      new: ReplacementEvents[event]
    });
    log.warn(msg);
    // eslint-disable-next-line no-console
    console.warn(msg);
  }
};

export class EventContainer<T> {
  private readonly subject: Subject<T>;
  private readonly subscriptions: Map<Handler<T>, Subscription>;
  private readonly scheduler?: SchedulerLike;

  constructor(scheduler?: SchedulerLike) {
    this.subject = new Subject<T>();
    this.subscriptions = new Map();
    this.scheduler = scheduler;
  }

  emit(eventData: T): void {
    this.subject.next(eventData);
  }

  subscribe(handler: Handler<T>): void {
    const subscription = this.scheduler
      ? this.subject.pipe(subscribeOn(this.scheduler)).subscribe(handler)
      : this.subject.subscribe(handler);
    this.subscriptions.set(handler, subscription);
  }

  unsubscribe(handler: Handler<T>): void {
    const subscription = this.subscriptions.get(handler);
    if (subscription) {
      subscription.unsubscribe();
    }
  }

  dispose(): void {
    for (const sub of this.subscriptions.values()) {
      sub.unsubscribe();
    }
    this.subscriptions.clear();
  }

  listSubscriptions(): [Handler<T>, Subscription][] {
    return Array.from(this.subscriptions.entries());
  }
}

export const isInEventEnum = (value: unknown): boolean => {
  return allEvents.includes(value as Events);
};

export default class EventEmitter implements Emitter {
  private static readonly _instance = new EventEmitter();
  readonly events: Map<string, EventContainer<EventData>>;

  private constructor() {
    this.events = new Map();
  }

  // This is used for testing
  static getNewInstance(): EventEmitter {
    return new EventEmitter();
  }

  /**
   * Get the static singleton instance. This is used for sdk global events.
   *
   * @returns singleton instance.
   */
  static getInstance(): EventEmitter {
    return EventEmitter._instance;
  }

  addEventListener(event: Events, listener: Handler<EventData>): void {
    if (!isInEventEnum(event)) {
      log.error('addEventListener adding unqualified event:', event);
    }
    warnIfDeprecatedEvent(event);
    let container = this.events.get(event);
    if (!container) {
      container = new EventContainer();
      this.events.set(event, container);
    }
    container.subscribe(listener);
  }

  removeAllListeners(eventName: Events): void {
    const container = this.events.get(eventName);
    if (container) {
      container.dispose();
    }
  }

  removeEventListener(eventName: Events, listener: Handler<EventData>): void {
    const container = this.events.get(eventName);
    if (container) {
      container.unsubscribe(listener);
    }
  }

  async dispatch(event: KsdkDispatchEvent): Promise<void> {
    if (!isInEventEnum(event.type)) {
      log.warn('emitting unqualified event:', event.type);
    }
    const container = this.events.get(event.type);
    if (container) {
      container.emit(event);
    }
  }

  emit(eventName: Events, eventData: EventData): void {
    if (!isInEventEnum(eventName)) {
      log.warn('emitting unqualified event:', eventName);
    }
    const container = this.events.get(eventName);
    if (container) {
      if (eventData && typeof eventData === 'object' && !eventData.type) {
        container.emit({
          type: eventName,
          ...eventData
        });
      } else {
        container.emit(eventData);
      }
    }
  }

  listEvents() {
    return Array.from(this.events.entries()).map(([name, container]) => {
      return [name, container.listSubscriptions()];
    });
  }

  clearEvents() {
    this.events.forEach((container, event) => {
      log.trace('cleaning up events for: ', event, container);
      container.dispose();
    });
  }
}
