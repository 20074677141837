import logger from '../../log';

const log = logger.getLogger(__filename);

/**
 * Check to see if an item is a string.
 *
 * @param obj - item to check.
 * @returns true if the item is a string.
 */
export function isString(obj: unknown): obj is string {
  return typeof obj === 'string' || obj instanceof String;
}

/**
 * Check to see if an item is an ArrayBuffer
 *
 * @param obj - item to check.
 * @returns true if the item can be classified as an ArrayBuffer
 */
export const isArrayBuffer = (obj: unknown): obj is ArrayBuffer => {
  return ArrayBuffer.isView(obj) || obj instanceof ArrayBuffer;
};

/**
 * Function to encode a string back to an ArrayBuffer, or do just pass the
 * original item through.
 */
type MaybeEncoder = <T>(input: T | string) => T | ArrayBuffer | string;

/**
 * Return value for maybeDecode, returning the data and an encoder if the
 * data will need to be re-encoded, otherwise a pass-through function.
 */
interface MaybeDecodeResult<T> {
  data: T | string;
  maybeEncode: MaybeEncoder;
}

/**
 * If obj is an ArrayBuffer decode it to string. Pass back an encoder which will
 * encode back to an ArrayBuffer if the obj was originally an ArrayBuffer.
 * @param obj - item to possibly decode to a string
 * @returns MaybeDecodeResult - containing a string, and a function to re-encode
 * the string (if the item was an ArrayBuffer), or the original item and a
 * pass-through function if the item was not an ArrayBuffer.
 */
export const maybeDecode = <T>(obj: T): MaybeDecodeResult<T> => {
  if (isArrayBuffer(obj)) {
    return {
      data: new TextDecoder('utf-8').decode(obj),
      maybeEncode: data => {
        return isString(data) ? new TextEncoder().encode(data) : data;
      }
    };
  }
  return { data: obj, maybeEncode: data => data };
};

/**
 * Check to see if an item is a string.
 *
 * @param obj - item to check.
 * @returns true if the item is a string.
 */
function isaNumber(obj: unknown): obj is number {
  return typeof obj === 'number' || obj instanceof Number;
}

/**
 * Check to see if an item is a string.
 *
 * @param obj - item to check.
 * @returns true if the item is a string.
 */
function isaStringArray(obj: unknown): obj is Array<string> {
  return obj instanceof Array && obj.length > 0 && isString(obj[0]);
}

/**
 * Check to see if an item is a string.
 *
 * @param obj - item to check.
 * @returns true if the item is a string.
 */
export function isaRTCIceCandidateArray(
  obj: unknown
): obj is RTCIceCandidate[] {
  return (
    obj instanceof Array && obj.length > 0 && obj[0] instanceof RTCIceCandidate
  );
}

/**
 * Check to see if an item is a string.
 *
 * @param obj - item to check.
 * @returns true if the item is a string.
 */
function isaNumberArray(obj: unknown): obj is Array<number> {
  return obj instanceof Array && obj.length > 0 && isaNumber(obj[0]);
}

/**
 * Decode URI encoded string. return null if it fails.
 * @param input - the string to URI decode
 * @returns decoded URI string or null if the decode fails.
 */
function decode(input: string): string | null {
  try {
    return decodeURIComponent(input.replace(/\+/g, ' '));
  } catch (e) {
    log.trace(`Unable to decode '${input}'`);
    return null;
  }
}

/**
 * Encode a string for use in a URI. return null if it fails.
 * @param input - the string to URI encode
 * @returns encoded URI string or null if the encode fails.
 */
function encode(input: string): string | null {
  try {
    return encodeURIComponent(input.replace(/\+/g, ' '));
  } catch (e) {
    log.trace(`Unable to encode '${input}'`);
    return null;
  }
}

/**
 * @param result - object to add key, value to
 * @param key - string
 * @param value - string
 * Adds the key value to the result, if the key already exists and is an
 * array add the item to the array, if the key exists and is not an array,
 * put the existing value and the new value into and array keyed by key.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
function merge(result: any, key: string, value: string): void {
  if (key in result) {
    if (result[key] instanceof Array) {
      result[key].push(value);
    } else {
      result[key] = [result[key], value];
    }
  } else {
    result[key] = value;
  }
}

function append(
  pairs: Array<string>,
  key: string,
  value: string | number
): void {
  const k: string | null = encode(key);
  const v: string | null = isString(value) ? encode(value) : value.toString();
  if (k === null || v === null) {
    return;
  }
  pairs.push(`${k}=${v}`);
}

/**
 * Assumes this is just the query string.
 * @param query - the original querystring.
 * @returns object parsed value of querystring.
 */
export function parse(query: string): Record<string, any> {
  if (typeof query === 'undefined' || !isString(query)) {
    return {};
  }
  const items = query.split('&');
  const result = {};

  items.forEach(item => {
    const parts = item.split('=');
    const key = decode(parts[0]);
    const value = decode(parts[1]);
    if (key && value !== undefined && value !== null) {
      merge(result, key, value);
    }
  });
  return result;
}

/**
 * @param obj - the object to transform.
 * @param prefix - boolean, undefined or string. If a string use prefix,
 * else if truthy and not a string use "?", else use ""
 * @returns string based on the object
 */
export function stringify(
  obj: unknown,
  prefix: string | boolean | undefined
): string {
  if (typeof obj === 'undefined') {
    return '';
  }
  const pairs: Array<string> = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  for (const [key, value] of Object.entries(obj as any)) {
    if (isString(key)) {
      if (isaStringArray(value) || isaNumberArray(value)) {
        value.forEach((item: string | number) => {
          append(pairs, key, item);
        });
      } else if (isString(value) || isaNumber(value)) {
        append(pairs, key, value);
      } else if (isaRTCIceCandidateArray(value)) {
        // write as jsonarray to key
        append(pairs, key, JSON.stringify(value));
      }
    }
  }
  if (!isString(prefix)) {
    prefix = prefix ? '?' : '';
  }
  return pairs.length ? prefix + pairs.join('&') : '';
}
