/**
 * Convert to boolean. If value is undefined or null or empty string, use default. Otherwise convert to a boolean
 * This will also make sure the string 'false' will indeed become false (include variants with capitalization).
 *
 *  @param val - value to convert
 *  @param defaultVal - fallback value.
 *  @returns val as boolean or defaultVal
 */
export default function setBool(val: unknown, defaultVal: boolean): boolean {
  // assume that the string 'false' is indeed false
  if (
    val &&
    (val as string).toLowerCase &&
    (val as string).toLowerCase() === 'false'
  ) {
    return false;
  }
  if (val !== undefined && val !== null && val !== '') {
    return Boolean(val);
  } else {
    return defaultVal;
  }
}
