export default class GenericStorage implements Storage {
  private _store: Map<string, string>;

  constructor() {
    this._store = new Map();
  }

  get length(): number {
    return this._store.size;
  }

  clear(): void {
    this._store = new Map();
  }

  getItem(name: string): string | null {
    const value = this._store.get(name);
    return typeof value === 'undefined' ? null : value;
  }

  key(n: number): string | null {
    if (n >= this._store.size) {
      return null;
    }
    return Array.from(this._store, ([key]) => key)[n];
  }

  removeItem(name: string): void {
    this._store.delete(name);
  }

  setItem(name: string, value: string): void {
    this._store.set(name, value);
  }
}
