import logger from '../log';

const log = logger.getLogger(__filename);

export default function loadScript(
  url: string,
  scriptTagId: string,
  elementToAppendTo: Element
): Promise<void> {
  return new Promise((res, rej) => {
    const scriptTag = document.createElement('script');
    scriptTag.id = scriptTagId;
    scriptTag.src = url;

    scriptTag.onload = () => {
      log.info(`loaded sdk from ${url}`);
      res();
    };
    scriptTag.onerror = e => {
      rej(e);
    };

    elementToAppendTo.appendChild(scriptTag);
  });
}
