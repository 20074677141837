import { v4 as uuidv4 } from 'uuid';
import { SafeGetArgs, safeGet, safeSave } from '../../util/storage';
import { LastDetectionEvent } from '../../reporter/constants';

const KEY_STORE_REPORTER = 'ksdn.r';

export interface ReporterStoredInfo {
  browserUserGuid: string;
  lastDetection?: LastDetectionEvent;
}

interface ReporterStoredInfoAndSavedInfo {
  data: ReporterStoredInfo;
  saved: boolean;
}

const baseArgs: SafeGetArgs<ReporterStoredInfo> = {
  key: KEY_STORE_REPORTER,
  default: {} as unknown as ReporterStoredInfo,
  which: 'localStorage'
};

function getData(): ReporterStoredInfoAndSavedInfo {
  const data = safeGet(baseArgs);
  if (data?.browserUserGuid) {
    return { data, saved: true };
  }
  return { data: { browserUserGuid: uuidv4() }, saved: false };
}

export function getStoredReportInfo(): ReporterStoredInfo {
  const { data, saved } = getData();
  if (!saved) {
    safeSave({ ...baseArgs, data });
  }
  return data;
}

export function saveStoredReportInfo(lastDetection?: LastDetectionEvent): void {
  const { data, saved } = getData();
  data.lastDetection = lastDetection ?? data.lastDetection;
  if (lastDetection || !saved) {
    safeSave({ ...baseArgs, data });
  }
}
