import setVar from './util/setVar';

export default class AgentConfig {
  disabled?: boolean;
  httpsEnabled?: boolean;
  port?: number;
  constructor(obj?: AgentConfig) {
    this.disabled = setVar(false, obj && obj.disabled);
    this.httpsEnabled = setVar(false, obj && obj.httpsEnabled);
    this.port = obj && obj.port;
  }
}
