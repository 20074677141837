/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export default function setVar(
  defaultValue: any,
  primary?: any,
  secondary?: any
): any {
  if (typeof primary !== 'undefined') {
    return primary;
  }
  if (typeof secondary !== 'undefined') {
    return secondary;
  }
  return defaultValue;
}
