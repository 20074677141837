import Api, { PluginConstructor, LoadOpts } from '../common/Api';
import ContentConfig, { MaybeContentConfig } from '../config/ContentConfig';
import EventEmitter from '../common/EventEmitter';
import PlayerType from '../player/PlayerType';
import extractJWTPayload from '../common/utils/extractJWTPayload';
import getBaseConfigFromBackend from './getBaseConfigFromBackend';
import getPlugin from './getPlugin';
import loadScript from './loadScript';
import logger from '../log';
import { ApiLogger } from '../log/Logger';
import {
  EventData,
  Plugin,
  KollectiveGlobalApi,
  IPlugin
} from '../common/interfaces';
import { Events, InternalEvents, KsdkDispatchEvent } from '../events';
import {
  FetchFactoryOptions,
  FlowplayerOptions,
  Handler,
  HLSjsConfigWrapper,
  KollectiveConstructor,
  PlayerAPI,
  SourceSetOptions,
  ViblastVideoJsPlayer,
  ViblastOptions,
  VideoJSOptions,
  VideoJSPlayer,
  XHRFactoryOptions,
  KollectiveLoadOptions,
  ApiEvents
} from '@kollective-frontend/ksdk-api';
import { TechApi } from './tech';
import { getEnvSuffix } from './util';
import configureReportLogging from '../common/configureReportLogging';
import validateBrowser from './validateBrowser';
import { GetSdkUriResponse } from '../api/getSdkUri';
import base64ToJson from '../common/utils/base64ToJson';

const log = logger.getLogger(__filename);
export const ksdkScriptId = 'ksdk::Script';

const {
  Bitmovin,
  FetchFactoryPlayer,
  XHRFactoryPlayer,
  Flowplayer,
  HLSjs,
  HLSjsConfig,
  Shaka,
  THEOplayer,
  Viblast,
  VideoJS
} = PlayerType;

const api = new Api();
const { PLAYER_READY } = InternalEvents;

declare global {
  interface Window {
    ksdk: KollectiveConstructor;
    Ksdk?: PluginConstructor;
  }
}

interface JITC {
  ah: string;
  tenantId: string;
  app: string;
}

//#region error reasons
export const INVALID_CONTENT_TOKEN =
  'Invalid contentToken - Could not decode token.';
export const INVALID_JITC = 'Invalid jitc - no api host or tenant id.';
export const INVALID_OPTIONS =
  'Invalid options - no content token, jit configuration and integrator token';
//#endregion error reasons

interface ContentTokenPayload {
  iss: string;
  ah: string;
  tid: string;
}

const handleContentToken = async (
  loader: Bootloader,
  opts: LoadOpts,
  token: string
): Promise<GetSdkUriResponse> => {
  const contentJwt = extractJWTPayload<ContentTokenPayload>(token);
  // if it is an empty object, it's invalid
  if (!contentJwt || Object.keys(contentJwt).length == 0) {
    return loader.playerReadyError(INVALID_CONTENT_TOKEN) as GetSdkUriResponse;
  }
  const envHostSuffix = getEnvSuffix(contentJwt.iss, 'content.');
  return await getBaseConfigFromBackend({
    apiHost: contentJwt.ah,
    tenantId: contentJwt.tid,
    token,
    envHostSuffix,
    overrideUrl: ((opts.config as MaybeContentConfig) || {}).sdkUrl
  });
};

const handleJitc = async (
  loader: Bootloader,
  opts: LoadOpts,
  token: string
): Promise<GetSdkUriResponse> => {
  const conf = base64ToJson(opts.jitc, 'Failed to decode jitc') as JITC;
  // if it is missing ah or tenantId, it's invalid.
  if (!conf.ah || !conf.tenantId) {
    return loader.playerReadyError(INVALID_JITC) as GetSdkUriResponse;
  }
  const envHostSuffix = getEnvSuffix(conf.ah, 'api.');
  return await getBaseConfigFromBackend({
    apiHost: conf.ah,
    tenantId: conf.tenantId,
    token,
    envHostSuffix,
    overrideUrl: ((opts.config as MaybeContentConfig) || {}).sdkUrl
  });
};

const callForConfig = async (
  loader: Bootloader,
  opts: LoadOpts
): Promise<GetSdkUriResponse> => {
  if (opts.contentToken) {
    return await handleContentToken(loader, opts, opts.contentToken);
  } else if (opts.jitc && opts.integratorToken) {
    return await handleJitc(loader, opts, opts.integratorToken);
  } else {
    return loader.playerReadyError(INVALID_OPTIONS) as GetSdkUriResponse;
  }
};

/**
 * Merges the various configs, sets up report logging, and gets the sdkUrl.
 * @param loader - the bootloader object.
 * @param config - the bootloader's content config.
 * @param opts - the options passed into the load call
 * @returns the sdk url
 * @throws rejects if ksdk is disabled or doesn't get a url.
 */
const mergeConfigAndGetSdkUrl = async (
  loader: Bootloader,
  config: ContentConfig,
  opts: LoadOpts
): Promise<string> => {
  const getSdkUriResponse = await callForConfig(loader, opts);
  config.merge(opts.config, { from: 'bootloader.opts' });
  config.merge(getSdkUriResponse, { from: 'sdk uri response' });
  configureReportLogging();
  if (getSdkUriResponse.disabled) {
    return loader.playerReadyError('ksdk is disabled') as string;
  }
  if (!getSdkUriResponse.sdkUrl) {
    return loader.playerReadyError('ksdk url is not available.') as string;
  }
  return getSdkUriResponse.sdkUrl;
};

export class Bootloader implements Plugin {
  hasLoadStarted?: boolean;
  isFinishedLoading?: boolean;
  playerType: string;
  player: PlayerAPI;
  playerPromise: Promise<EventData>;
  playerOptions?: unknown;
  log: ApiLogger;
  private readonly _tech: TechApi;
  private handlersToBind: Map<string, Handler<unknown>[]>;
  private eventsToSend: KsdkDispatchEvent[];
  private config: ContentConfig;

  constructor(playerType: string, player: PlayerAPI, playerOptions?: unknown) {
    this._tech = ksdk._internal.tech;
    this.playerType = playerType;
    this.player = player;
    this.playerOptions = playerOptions;
    this.log = log;
    this.handlersToBind = new Map();
    this.eventsToSend = [];
    this.config = ContentConfig.getInstance();
    this.playerPromise = new Promise(resolve => {
      EventEmitter.getInstance().addEventListener(PLAYER_READY, async event => {
        resolve(event.data);
      });
    });
  }

  mergeConfig(config: MaybeContentConfig): void {
    const plugin = getPlugin();
    if (typeof plugin?.mergeConfig === 'function') {
      plugin.mergeConfig(config);
    }
    this.config.merge(config);
  }

  async load(opts: LoadOpts = {}): Promise<Plugin> {
    this.hasLoadStarted = true;
    const validationError = validateBrowser();
    if (validationError) {
      api.setDisabled();
      // eslint-disable-next-line no-console
      console.warn(validationError);
      return this.playerReadyError(validationError) as Plugin;
    }

    const sdkUrl = await mergeConfigAndGetSdkUrl(this, this.config, opts);
    try {
      await loadScript(sdkUrl, ksdkScriptId, document.body);
    } catch (e) {
      log.error('Failed to load sdk script', e);
    }
    if (!window.Ksdk) {
      return this.playerReadyError(
        'ksdk is not available on the window.'
      ) as Plugin;
    }
    await this.updateInstance(window.Ksdk, opts as KollectiveLoadOptions);
    return this;
  }

  /**
   * Instantiates the plugin attaches it to the api, dispatches notifications
   * that everything is done, and calls load on the plugin.
   * @param Ksdk - the constructor for the plugin
   * @param options - the options passed into the load function
   */
  protected async updateInstance(
    Ksdk: PluginConstructor,
    options: KollectiveLoadOptions
  ): Promise<Plugin> {
    const inst = new Ksdk(
      this.playerType,
      this.player,
      this.playerOptions,
      this._tech
    );
    api.setPlugin(inst);
    this.handleEventSetup(inst);
    this.dispatch({ type: InternalEvents.CONFIG_UPDATE, data: this.config });
    inst.mergeConfig(this.config);
    this.player = await inst.load(options).then(i => i.getPlayer());
    this.dispatch({
      type: PLAYER_READY,
      data: { item: inst as unknown as IPlugin }
    });
    return inst;
  }

  async handleEventSetup(inst: Plugin) {
    // Send all the before binding the listeners so we don't send them twice to some listeners.
    this.eventsToSend.forEach(event => inst.emitter?.dispatch(event));
    this.eventsToSend = [];
    // rebind events before load is called.
    this.handlersToBind.forEach((value, key) => {
      value.forEach(v => {
        // Need to Cleanup preparation handlers
        EventEmitter.getInstance().removeEventListener(key as Events, v);
        inst.addEventListener(key, v);
      });
    });
    this.handlersToBind.clear();
  }

  async dispatch(event: KsdkDispatchEvent): Promise<void> {
    const plugin = getPlugin();
    if (plugin) {
      await plugin.emitter?.dispatch(event);
    } else {
      // Need to be able to report events that happened before plugin is initialized.
      this.eventsToSend.push(event);
      await EventEmitter.getInstance().dispatch(event);
    }
  }

  addEventListener(name: string, handler: Handler<unknown>): void {
    const plugin = getPlugin();
    if (plugin) {
      plugin.addEventListener(name, handler);
    } else {
      // Need to be able to attach listeners before plugin is initialized.
      EventEmitter.getInstance().addEventListener(name as Events, handler);
      const hs = this.handlersToBind.get(name) ?? [];
      if (hs.length === 0) {
        this.handlersToBind.set(name, hs);
      }
      hs.push(handler);
    }
  }

  getConfig(): ContentConfig {
    return ContentConfig.getCopy();
  }

  isEcdnUsed(): boolean {
    const result = getPlugin()?.isEcdnUsed() ?? false;
    return this.isFinishedLoading ? result : true;
  }

  async getPlayer(): Promise<PlayerAPI> {
    if (!this.hasLoadStarted) {
      return this.handleError('getPlayer called before load') as PPAPI;
    }
    return this.playerPromise.then((item: EventData) => {
      if (item.item) {
        return item.item.getPlayer();
      } else {
        return this.handleError(
          'unable to retrieve player from plugin'
        ) as PPAPI;
      }
    });
  }

  async sourceSet<T extends SourceSetOptions<PlayerAPI>>(
    url: string,
    options?: T
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const data = { url, options, resolve, reject };
      this.dispatch({ type: InternalEvents.SOURCE_SET, data });
    });
  }

  stop(): void {
    const internal = (window.ksdk as KollectiveGlobalApi)._internal;
    if (internal && internal.plugin) {
      // We are resetting the plugin, cleanup old state.
      internal.plugin.stop();

      internal.plugin = undefined;
    }
    EventEmitter.getInstance().clearEvents();
  }

  handleError = (reason: string): unknown => {
    this.dispatch({ type: ApiEvents.ERROR, data: { reason } });
    return Promise.reject(new Error(reason));
  };

  // Used when the load completes incorrectly, sends the PLAYER_READY event which
  // resolves playerPromise, so that `getPlayer` works correctly.
  playerReadyError(msg: string): unknown {
    this.isFinishedLoading = true;
    this.dispatch({ type: PLAYER_READY, data: {} });
    return this.handleError(msg);
  }
}

type PPAPI = Promise<PlayerAPI>;

const create = (
  playerType: string,
  player: PlayerAPI,
  playerOptions?: unknown
): Bootloader => {
  return new Bootloader(playerType, player, playerOptions);
};

function ksdk(
  playerType: string,
  player: PlayerAPI,
  playerOptions: unknown
): Bootloader {
  return create(playerType, player, playerOptions);
}

ksdk.forBitmovin = function (playerOptions: unknown): Bootloader {
  return create(Bitmovin, undefined, playerOptions);
};

ksdk.forViblast = function (options: ViblastOptions): Bootloader {
  return create(Viblast, {} as unknown as ViblastVideoJsPlayer, options);
};

ksdk.forViblastVideoJS = function (player: ViblastVideoJsPlayer): Bootloader {
  return create(Viblast, player);
};

ksdk.forVideoJS = function (
  player: VideoJSPlayer,
  playerOptions: VideoJSOptions
): Bootloader {
  return create(VideoJS, player, playerOptions);
};

ksdk.forShaka = function (player: PlayerAPI): Bootloader {
  return create(Shaka, player);
};

ksdk.forFlowplayer = function (
  player: PlayerAPI,
  options?: FlowplayerOptions
): Bootloader {
  return create(Flowplayer, player, options);
};

ksdk.forHLSjs = function (
  videoElement: HTMLVideoElement,
  hlsConfig: unknown
): Bootloader {
  return create(HLSjs, videoElement, hlsConfig);
};

ksdk.forHLSjsConfig = (config: HLSjsConfigWrapper): Bootloader => {
  return create(HLSjsConfig, config);
};

ksdk.forTHEOplayer = function (player: PlayerAPI): Bootloader {
  return create(THEOplayer, player);
};

ksdk.forFetchFactory = (options: FetchFactoryOptions): Bootloader => {
  return create(FetchFactoryPlayer, options);
};

ksdk.forXHRFactory = (options: XHRFactoryOptions): Bootloader => {
  return create(XHRFactoryPlayer, options);
};

ksdk._internal = { tech: new TechApi() };
ksdk.api = api;
window.ksdk = ksdk as unknown as KollectiveGlobalApi;

export default ksdk;
