import logger from '../log';
import cleanse from './util/cleanse';
import merge from 'lodash/merge';
import AgentConfig from './AgentConfig';
import BaseLogger from '../log/Logger';
import LegacyContentConfig from './LegacyContentConfig';
import PeeringConfig from './PeeringConfig';
import PlaybackConfig from './PlaybackConfig';
import ReportConfig from './ReportConfig';
import ContentSessionRetryConfig, {
  newContentSessionRetryConfig
} from './ContentSessionRetryConfig';
import setVar from './util/setVar';
import cloneDeep from 'lodash/cloneDeep';
import proxyConfig, { ProxyConfig } from './ProxyConfig';
import { safeGet } from '../util/storage';
import { getProbeRequests, ProbeRequest } from './ProbeRequests';
const log = logger.getLogger(__filename) as BaseLogger;

const CONFIG_OVERRIDE_KEY = 'ksdk::test::config';

export class ContentConfigMergeOptions {
  logData?: Record<string, unknown>;
  from: string;
  constructor(obj?: ContentConfigMergeOptions) {
    this.logData = obj && obj.logData;
    this.from = (obj && obj.from + ': ') || '';
  }
}

export interface MaybeContentConfig {
  agent?: AgentConfig;
  peering?: PeeringConfig;
  playback?: PlaybackConfig;
  report?: ReportConfig;
  reportingContext?: ReportingContext;
  contentSessionRetry?: ContentSessionRetryConfig;
  proxy?: ProxyConfig;

  labels?: string[]; // part of peeringV2
  localities?: string[]; // part of peeringV2
  networkPolicies?: string[];
  networkLocationName?: string;
  probeUrls?: string[]; // list of URLs to be probed for locality resolution
  probeRequests?: ProbeRequest[];
  tags?: string[];
  // Local only: this is until we have the probeUrls api XXX: until probeurls / 449 available.
  requestProbeUrls?: boolean; // Set to true/remove when probeurls endpoint available.

  // General v11 fields
  app?: string; // application name
  ah?: string;
  ch?: string; // content host
  contentToken?: string; // token for the content
  force?: boolean; // Use passed in config instead of config from content.
  sgh?: string; // signal host
  sth?: string; // stats host
  sca?: string; // Now: ch, semi deprecated
  th?: string; // tenant host
  tenantId?: string; // what's in a name
  sdkUrl?: string; // Url to load the "plugin" from the bootloader
}
type LCCorCC = LegacyContentConfig | MaybeContentConfig;

const selectContentHost = (ch?: string, sca?: string): string | undefined => {
  if (ch) {
    return ch;
  }
  try {
    return sca && new URL(sca).origin;
  } catch (e) {
    return undefined;
  }
};

const getOverride = (): MaybeContentConfig => {
  const args = { default: null, key: CONFIG_OVERRIDE_KEY, log };
  const sessionOverride = safeGet(args);
  if (sessionOverride) {
    return sessionOverride;
  }
  return safeGet({ ...args, default: {}, which: 'localStorage' });
};

export interface ReportingContext {
  internalIps?: string[];
  externalIps?: string[];
  iceCandidates?: unknown[];
}

export default class ContentConfig {
  protected static readonly _instance: ContentConfig = new ContentConfig();
  agent: AgentConfig;
  peering: PeeringConfig;
  playback: PlaybackConfig;
  report: ReportConfig;
  reportingContext: ReportingContext;
  contentSessionRetry: ContentSessionRetryConfig;
  proxy?: ProxyConfig;

  labels?: string[]; // part of peeringV2
  localities?: string[]; // part of peeringV2
  networkPolicies?: string[];
  networkLocationName?: string;
  probeRequests?: ProbeRequest[]; // list of URLs to be probed for locality resolution
  tags?: string[];
  // Local only: this is until we have the probeUrls api XXX: until probeurls / 449 available.
  requestProbeUrls: boolean; // Set to true/remove when probeurls endpoint available.

  // General v11 fields
  app?: string; // application name
  ah?: string;
  ch?: string; // content host
  contentToken?: string; // token for the content
  force: boolean; // Use passed in config instead of config from content.
  sgh?: string; // signal host
  sth?: string; // stats host
  sca?: string; // Now: ch, semi deprecated
  th?: string; // tenant host
  tenantId?: string; // what's in a name
  sdkUrl?: string; // Url to load the "plugin" from the bootloader

  constructor(conf?: LCCorCC) {
    const obj = conf as LegacyContentConfig;

    // v11
    this.app = obj && obj.app;
    this.ah = obj && obj.ah;
    this.ch = obj && selectContentHost(obj.ch, obj.sca);
    this.contentToken = obj && (obj.contentToken || obj.token);
    this.force = setVar(false, obj && obj.force);
    this.sgh = obj && obj.sgh;
    this.sth = obj && obj.sth;
    this.sca = obj && obj.sca;
    this.th = obj && obj.th;
    this.tenantId = obj && obj.tenantId;
    this.sdkUrl = obj && obj.sdkUrl;

    // We are not strict on this element, it can have any fields.
    this.reportingContext = obj?.reportingContext;

    this.agent = new AgentConfig(obj?.agent);
    this.peering = new PeeringConfig(obj?.peering, obj);
    this.playback = new PlaybackConfig(obj?.playback);
    this.report = new ReportConfig(obj?.report, obj);
    this.contentSessionRetry = newContentSessionRetryConfig(
      obj?.contentSessionRetry
    );
    this.tags = (obj && obj.tags) || [];

    // peering
    this.labels = obj && obj.labels;
    this.localities = obj && obj.localities;
    this.networkPolicies = obj?.networkPolicies;
    this.networkLocationName = obj?.networkLocationName;
    this.probeRequests = getProbeRequests(obj || {});
    this.requestProbeUrls = setVar(false, obj && obj.requestProbeUrls);

    //proxy
    this.proxy = proxyConfig(obj && obj.proxy);
  }

  public static getInstance(conf?: LCCorCC): ContentConfig {
    if (conf) {
      this._instance.merge(conf);
    }
    return this._instance;
  }

  public static getCopy(): ContentConfig {
    return ContentConfig.getNewInstance(this._instance);
  }

  public static getNewInstance(conf?: LCCorCC): ContentConfig {
    return new ContentConfig(conf);
  }

  /**
   * @param config - another content config to merge into this.
   * @param opts - \{ logData, from \}
   * @returns the original ContentConfig before merge or the to be merged config if force is set
   */
  merge(
    config?: LegacyContentConfig | MaybeContentConfig,
    opts?: ContentConfigMergeOptions
  ): ContentConfig {
    if (!config) {
      return this;
    }
    opts = new ContentConfigMergeOptions(opts);
    if (!config) {
      log.debug(
        opts.from + 'ContentConfig missing, unable to merge',
        opts.logData
      );
      return this;
    }
    const conf = new ContentConfig(config);
    if (this.force) {
      const logInfo = { ...opts.logData, orig: this, new: conf };
      log.debug(
        opts.from + "Not using newer config because of 'force'",
        logInfo
      );
      return conf;
    }
    const orig = cloneDeep(this);
    const override = getOverride();
    this.replace(
      new ContentConfig(merge(cleanse(orig), cleanse(conf), override))
    );
    log.debug(opts.from + 'Merging ContentConfig', {
      orig,
      override,
      config: cloneDeep(this),
      new: conf
    });
    return orig;
  }

  /**
   * Replaces the content of 'this' so that once an object has a copy of the
   * config they will always get updates.
   * @param obj - the replacement source.
   */
  replace(obj: ContentConfig): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!obj || !(obj instanceof ContentConfig)) {
      return;
    }
    // v11
    this.app = obj.app;
    this.ch = obj.ch;
    this.contentToken = obj.contentToken;
    this.sdkUrl = obj.sdkUrl;
    this.force = obj.force;
    this.sgh = obj.sgh;
    this.sth = obj.sth;
    this.sca = obj.sca;
    this.th = obj.th;
    this.tenantId = obj.tenantId;
    this.sdkUrl = obj.sdkUrl;

    this.reportingContext = obj.reportingContext;

    this.agent = obj.agent;
    this.peering = obj.peering;
    this.playback = obj.playback;
    this.report = obj.report;
    this.contentSessionRetry = obj.contentSessionRetry;
    this.tags = obj.tags;

    // peering
    this.labels = obj.labels;
    this.localities = obj.localities;
    this.networkPolicies = obj.networkPolicies;
    this.networkLocationName = obj.networkLocationName;
    this.probeRequests = obj.probeRequests;
    this.requestProbeUrls = obj.requestProbeUrls;

    // proxy
    this.proxy = obj.proxy;
  }
}
