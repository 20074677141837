import ContentConfig from '../../config/ContentConfig';
import SDKConfig from '../../common/SDKConfig';
import cleanse from '../../config/util/cleanse';
import getKollectiveHeaders from '../../common/utils/getKollectiveHeaders';
import logger from '../../log';
import getAgentId from '../../util/getAgentId';
import { getStoredReportInfo } from '../../common/Reporter/storeReportInfo';
import {
  Diagnostic,
  DiagnosticType,
  GetDiagnosticUrlArgs,
  SendDiagnosticArgs,
  SendSessionDiagnosticArgs,
  ServiceDiagnostic,
  ServiceDiagnosticType,
  ServiceBootstrapDiagnostic,
  ServiceSessionDiagnostic,
  UrlDiagnosticType
} from './constants';

const log = logger.getLogger(__filename);

export const getDiagnosticUrl = (args: GetDiagnosticUrlArgs): string => {
  if (!args.statsHost && !args.envHostSuffix) {
    const ch = ContentConfig.getInstance().ch;
    if (!ch) {
      return '';
    }
    args.envHostSuffix = ch.replace(/^[^.]*./, '');
    if (!args.envHostSuffix) {
      return '';
    }
  }
  const type = args.type.toLowerCase();
  const host = args.statsHost || `https://stats.${args.envHostSuffix}`;
  const path = `/api/diagnostics/type/${type}/code/${args.code}`;
  return new URL(path, host).toString();
};

export const generateBaseBody = (
  args: SendDiagnosticArgs,
  type: DiagnosticType
): ServiceDiagnostic => {
  const { version } = new SDKConfig();
  return {
    agentId: (args.agentId || getAgentId()) as string,
    code: args.code,
    reporter: 'SDK',
    reporterId: args.reporterId || getStoredReportInfo().browserUserGuid,
    reporterVersion: version as string,
    tenantId: args.tenantId,
    timestamp: args.timestamp || Date.now(),
    type: type.toUpperCase() as ServiceDiagnosticType
  };
};

export const sendSessionDiagnostic = async (
  args: SendSessionDiagnosticArgs
): Promise<void> => {
  if (!args.statsHost && !args.envHostSuffix) {
    return await sendBootstrapDiagnostic(args);
  }
  const type: DiagnosticType = 'session';
  const diag: ServiceSessionDiagnostic = {
    ...generateBaseBody(args, type),
    type: 'SESSION',
    app: args.app,
    clusterId: args.clusterId,
    contentId: args.contentId,
    data: args.data,
    localities: args.localities,
    message: `${args.message}`,
    nodeId: args.nodeId,
    sessionId: args.sessionId
  };
  await sendDiagnostic(args, diag);
};

export const sendBootstrapDiagnostic = async (
  args: SendDiagnosticArgs
): Promise<void> => {
  const type: DiagnosticType = 'bootstrap';
  const diag: ServiceBootstrapDiagnostic = {
    ...generateBaseBody(args, type),
    type: 'BOOTSTRAP'
  };
  await sendDiagnostic(args, diag);
};

export const sendDiagnostic = async (
  args: SendDiagnosticArgs,
  diag: Diagnostic
): Promise<void> => {
  const type = diag.type.toLowerCase() as UrlDiagnosticType;
  const body = JSON.stringify(cleanse(diag));
  const headers = {
    ...getKollectiveHeaders({ ...args, sdkConfig: new SDKConfig() }),
    'Content-Type': 'application/json'
  };
  const method = 'POST';
  const requestInit: RequestInit = { method, mode: 'cors', headers, body };
  const url = getDiagnosticUrl({ ...args, type });
  if (!url) {
    log.warn('Cannot send diagnostic, unable to get diagnostic url');
    return;
  }
  log.trace({ url, headers, diag });
  const response = await fetch(url, requestInit);
  if (!response.ok) {
    await fetch(url, requestInit);
  }
};
