import autobind from '../autobind';
import SessionLogger from './SessionLogger';
import { v4 as uuidv4 } from 'uuid';
import { getStoredLoggerInfo, saveStoredLoggerInfo } from './storeLoggerInfo';
import Appender, { LogData } from './Appender';
import ConsoleAppender from './ConsoleAppender';
import { Level, LogLevels, LogType, ShortLogLevels } from './LoggerConstants';
import { AppenderData, Levels, LogLevel, LogMessage } from './Interfaces';

function getOrDefault<T>(v: T | undefined | null, d: T): T {
  return v ? v : d;
}

interface Appenders {
  [key: string]: Appender;
}

function iterateAppenders(appenders: Appenders): Appender[] {
  return Object.keys(appenders).map(prop => appenders[prop]);
}

function getAppenders(
  appenderData: AppenderData[] | undefined,
  rootLevel: LogLevel<Level>
): Appenders {
  if (!appenderData || appenderData.length < 1) {
    return { [ConsoleAppender.NAME]: new ConsoleAppender(true, rootLevel) };
  }
  return appenderData.reduce((acc, d) => {
    switch (d.name) {
      case ConsoleAppender.NAME:
        return { ...acc, [d.name]: new ConsoleAppender(d.enabled, d.level) };
    }
    return acc;
  }, {});
}

export interface ApiLogger {
  setConsoleLevel: (level: Level, category?: string) => string;
  setSessionLogging: (category: string, on?: boolean, save?: boolean) => string;
  clearSessionData: (category?: string) => void;
  setConsoleType: (type: string) => string;
}

export abstract class Logger implements Levels, ApiLogger {
  readonly TRACE: Level.TRACE;
  readonly DEBUG: Level.DEBUG;
  readonly INFO: Level.INFO;
  readonly ERROR: Level.ERROR;
  readonly WARN: Level.WARN;
  readonly FATAL: Level.FATAL;
  readonly OFF: Level.OFF;

  protected constructor() {
    autobind(this);

    /// Set up
    this.TRACE = Level.TRACE;
    this.DEBUG = Level.DEBUG;
    this.INFO = Level.INFO;
    this.ERROR = Level.ERROR;
    this.WARN = Level.WARN;
    this.FATAL = Level.FATAL;
    this.OFF = Level.OFF;
  }

  asApiLogger(): ApiLogger {
    return {
      setConsoleLevel: (level: Level, category?: string) => {
        return this.setConsoleLevel(level, category);
      },
      setSessionLogging: (category: string, on?: boolean, save?: boolean) => {
        return this.setSessionLogging(category, on, save);
      },
      clearSessionData: (category?: string): void => {
        this.clearSessionData(category);
      },
      setConsoleType: (type: string): string => {
        return this.setConsoleType(type);
      }
    };
  }

  /**
   * @param appender - an additional appender to append log messages to.
   */
  abstract addAppender(appender: Appender): void;

  abstract setConsoleType(type: string): string;

  abstract getLevel(category?: string): LogLevel<Level>;
  abstract isEnabled(level: Level, category?: string): boolean;

  /**
   * @returns true if the logger is turned on.
   */
  abstract enabled(): boolean;

  /**
   * @param level - the messages severity level
   * @param msg - a list of log message components to log.
   */
  abstract message(level: LogLevel<Level>, ...msg: LogMessage[]): void;

  /**
   * Save the state
   */
  abstract save(): void;

  /**
   * Set the level of the logger[/appender[/category]]
   * @param level - the severity of messages accepted.
   * @param name - optional parameter to determine which appender to set the logging level
   */
  abstract setLevel(level: Level, name?: string, category?: string): string;

  setConsoleLevel(level: Level, category?: string): string {
    return this.setLevel(level, ConsoleAppender.NAME, category);
  }

  abstract getSessionLogger(category?: string): SessionLogger;

  setSessionLogging(category: string, on?: boolean, save?: boolean): string {
    const sessionLogger = this.getSessionLogger(category);
    return sessionLogger.setSessionLogging({
      on,
      section: category,
      getFile: save
    });
  }

  clearSessionData(category?: string): void {
    this.getSessionLogger(category || '').clearSessionData(category);
  }

  /**
   * @param name - if set only clear appender with `name`
   * Clear the logging levels
   */
  clearLevels(name?: string): boolean {
    this.setLevel(Level.OFF, name);
    return true;
  }

  /**
   * @param type - Set the log type (ENHANCED sets the console message to log by console
   *        levels instead of just prepending the level).
   */
  abstract setType(type: LogType | string): void;

  trace(...msg: LogMessage[]): void {
    this.message(LogLevels.TRACE, ...msg);
  }

  debug(...msg: LogMessage[]): void {
    this.message(LogLevels.DEBUG, ...msg);
  }

  info(...msg: LogMessage[]): void {
    this.message(LogLevels.INFO, ...msg);
  }

  error(...msg: LogMessage[]): void {
    this.message(LogLevels.ERROR, ...msg);
  }

  warn(...msg: LogMessage[]): void {
    this.message(LogLevels.WARN, ...msg);
  }

  fatal(...msg: LogMessage[]): void {
    this.message(LogLevels.FATAL, ...msg);
  }
}

/**
 * Wrapper for the BaseLogger. All calls are redirected to the BaseLogger.
 * Additional components:
 *   * category: the category of the message (this is used by the Reporter appender).
 */
export class LoggerWithType extends Logger {
  private log: BaseLogger;
  readonly category: string;

  protected constructor(log: BaseLogger, category: string) {
    super();
    autobind(this);
    // Type is the category based on filename
    this.category = category;
    this.log = log;
  }

  static create(log: BaseLogger, filename: string): LoggerWithType {
    return new LoggerWithType(log, filename);
  }

  addAppender(appender: Appender): void {
    this.log.addAppender(appender);
  }
  enabled(): boolean {
    return this.log.enabled();
  }

  getLogger(obj: string): Logger {
    return this.log.getLogger(obj);
  }

  getSessionLogger(category?: string): SessionLogger {
    return this.log.getSessionLogger(category || this.category);
  }

  message(level: LogLevel<Level>, ...msg: LogMessage[]): void {
    this.log._message(new LogData(level, msg, this.category));
  }

  save(): void {
    this.log.save();
  }

  setLevel(level: Level, name?: string, category?: string): string {
    return this.log.setLevel(level, name, category);
  }

  setConsoleType(type: string): string {
    return this.log.setConsoleType(type);
  }

  setType(type: string): void {
    this.log.setType(type);
  }

  getLevel(category?: string): LogLevel<Level> {
    category = typeof category === 'string' ? category : this.category;
    return this.log.getLevel(category);
  }

  isEnabled(level: Level, category?: string): boolean {
    category = typeof category === 'string' ? category : this.category;
    return this.log.isEnabled(level, category);
  }
}

export default class BaseLogger extends Logger {
  id: string;
  rootLevel: LogLevel<Level>;
  appenders: Appenders;
  loggers: Record<string, LoggerWithType>;
  sessionLoggers: Record<string, SessionLogger>;

  /**
   * Get a logger with the category set (this is to be used for webrtc/ecdn11/...)
   * to help with debugging.
   * @param category - the "name" of the logger, i.e. webrtc, this is a list so that
   *        you can add the filename or other data that you want to stick to the
   *        logger that is created. This replaces the existing logger type(s).
   */
  getLogger(category: string): Logger {
    let logger = this.loggers[category];
    if (!logger) {
      logger = LoggerWithType.create(this, category);
      this.loggers[category] = logger;
    }
    return logger;
  }

  getSessionLogger(category: string): SessionLogger {
    let sessionLogger = this.sessionLoggers[category];
    if (!sessionLogger) {
      const logger = this.getLogger(category) as BaseLogger;
      sessionLogger = new SessionLogger(category, logger, this.sessionLoggers);
      this.sessionLoggers[category] = sessionLogger;
    }
    return sessionLogger;
  }

  static create(): BaseLogger {
    const { id, rootLevel, appenderData } = getStoredLoggerInfo() || {};
    const level = getOrDefault(rootLevel, LogLevels.ERROR);
    const appenders = getAppenders(appenderData, level);
    return new BaseLogger(getOrDefault(id, uuidv4()), level, appenders);
  }

  protected constructor(
    id: string,
    rootLevel: LogLevel<Level>,
    appenders: Appenders
  ) {
    super();
    autobind(this);
    this.id = id;
    this.rootLevel = rootLevel;
    this.appenders = appenders;
    this.loggers = {};
    this.sessionLoggers = {};
  }

  save(): void {
    const { id, rootLevel, appenders } = this;
    saveStoredLoggerInfo({
      id,
      rootLevel,
      appenderData: iterateAppenders(appenders)
    });
  }

  message(level: LogLevel<Level>, ...msg: LogMessage[]): void {
    this._message(new LogData(level, msg));
  }

  _message(data: LogData): void {
    if (!this.enabled()) {
      return;
    }
    //if (data.level.value < 3) { console.error('xxy:', data); }
    iterateAppenders(this.appenders).forEach(appender => {
      appender.message(data);
    });
  }

  enabled(): boolean {
    return iterateAppenders(this.appenders).reduce(
      (acc: boolean, n): boolean => n.enabled || acc,
      false
    );
  }

  addAppender(appender: Appender): void {
    if (!appender || !this.appenders) {
      return;
    }
    this.appenders[appender.name] = appender;
  }

  determineLogLevel(level: string | LogLevel<Level>): LogLevel<Level> | string {
    // for js land, when the level doesn't exist
    if (!level) {
      return `unable to set ${level} as it doesn't exist`;
    }
    let name;
    if (typeof level === 'string') {
      name = level.toUpperCase();
    } else if (level.label) {
      name = level.label;
    } else {
      return `unable to set ${level} as it is not an available level`;
    }
    const logLevel: LogLevel<Level> | undefined =
      LogLevels[name] || ShortLogLevels[name];
    if (!logLevel) {
      return `unable to set ${level} as it doesn't match an existing level`;
    }
    return logLevel;
  }

  /**
   * @param level - the level to set the logging to.
   * Set the root logger level with a string. Use Logger.\{DEBUG,ERROR,etc\} for convenience
   * ex. log.setLevel(log.DEBUG)
   */
  setLevel(level: Level, name?: string, category?: string): string {
    const logLevel: LogLevel<Level> | string = this.determineLogLevel(level);
    if (typeof logLevel === 'string') {
      return logLevel; // Returning as an error message
    }

    for (const appender of iterateAppenders(this.appenders)) {
      appender.setLevel(logLevel, name, category);
    }
    this.save();
    const msg = `Logging updated, level set to ${level} for appender`;
    return `${msg} ${name || 'all'}, category ${category || 'all'}`;
  }

  getLevel(category?: string): LogLevel<Level> {
    return Object.values(this.appenders).reduce((acc, appender) => {
      const lvl = appender.lookupLevel(category || '') || appender.level;
      return acc.value < lvl.value ? lvl : acc;
    }, LogLevels.OFF);
  }

  isEnabled(level: Level, category?: string): boolean {
    const lvl = this.getLevel(category);
    const m = LogLevels[level];
    return m.value <= lvl.value;
  }

  setConsoleType(type: string): string {
    this.appenders.CONSOLE.setType(type);
    const newType = this.appenders.CONSOLE.logType;
    return `Console Logging type set to ${newType}`;
  }

  setType(type: LogType | string): string {
    iterateAppenders(this.appenders).forEach(appender => {
      appender.setType(type);
    });
    const out = this.appenders.CONSOLE.logType;
    return `Logging type set to ${out}`;
  }
}
