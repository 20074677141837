import { getLevel, Level } from './LoggerConstants';
import { LogMessage } from './Interfaces';
import Appender, { LogData } from './Appender';
import { Emitter } from '../common/interfaces';
import { InternalEvents } from '../events';

export interface IReporterData {
  ts: number;
  msg: string[];
}

export class ReporterData implements IReporterData {
  ts: number;
  msg: string[];
  constructor(obj?: ReporterData) {
    this.ts = (obj && obj.ts) || -1;
    this.msg = (obj && obj.msg) || [];
  }
}

export class ReporterMessage {
  type: string;
  level: string;
  data: ReporterData;

  constructor(obj?: ReporterMessage) {
    this.type = (obj && obj.type) || '';
    this.level = (obj && obj.level) || '';
    this.data = (obj && obj.data) || new ReporterData();
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toString = (value: any, fields?: string[]): string => {
  try {
    return JSON.stringify(value, fields);
  } catch (err) {
    return '[unparsable object]';
  }
};

// Ignored fields: arguments, columnNumber, fileName, lineNumber, stack
const ERROR_FIELDS = ['message', 'name', 'type'];

const generatePayload = (msg: LogMessage[]): string[] => {
  const payload: string[] = [];
  msg.forEach(value => {
    if (typeof value === 'string') {
      payload.push(value);
    } else if (value instanceof Error) {
      payload.push(toString(value, ERROR_FIELDS));
    } else {
      payload.push(toString(value));
    }
  });
  return payload;
};

const logDataToReporterMessage = (data: LogData): ReporterMessage => {
  return {
    type: data.type,
    level: data.level.label,
    data: {
      ts: data.ts,
      msg: generatePayload(data.msg)
    }
  };
};

export default class ReporterAppender extends Appender {
  static NAME = 'REPORTER';
  emitter: Emitter;

  constructor(enabled: boolean, level: Level, emitter: Emitter) {
    super(
      enabled,
      getLevel(level),
      ReporterAppender.NAME,
      getLevel(Level.INFO)
    );
    this.emitter = emitter;
  }

  /*
   * Logs are formatted according to:
   * https://kollective.atlassian.net/wiki/spaces/IN/pages/81428487
   * {
   *   "type": "{playback_type}",
   *   "level": "{fatal|error|warn|info}",
   *   "data": ErrorObject.toJson()
   * }
   */
  handle(data: LogData): void {
    if (!data.type) {
      // Only log items with a type.
      return;
    }
    const msg = logDataToReporterMessage(data);
    this.emitter.emit(InternalEvents.LOG, { data: msg });
  }
}
