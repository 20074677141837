import logger from '../log';
import { ITechApi } from '@kollective-frontend/ksdk-api';

const log = logger.getLogger(__filename);
export type UUFunction = (...item: unknown[]) => unknown;

export class TechApi implements ITechApi {
  private techMap: Map<string, TechApiFunction>;
  constructor() {
    this.techMap = new Map();
  }

  getTechApiFunction(name: string): TechApiFunction {
    let techApiFunction = this.techMap.get(name);
    if (!techApiFunction) {
      techApiFunction = new TechApiFunction(name);
      this.techMap.set(name, techApiFunction);
    }
    return techApiFunction;
  }
  get(name: string): UUFunction {
    const item = this.getTechApiFunction(name);
    return (...items: unknown[]): unknown => {
      return item.func(...items);
    };
  }
  get size(): number {
    return this.techMap.size;
  }
}

export class TechApiFunction {
  readonly name: string;
  private _func?: (...args: unknown[]) => unknown;
  constructor(name: string) {
    this.name = name;
  }

  setFunction(f: (...args: unknown[]) => unknown): void {
    this._func = f;
  }

  func(...args: unknown[]): unknown {
    log.trace('Calling tech api for:', this.name, { func: this._func, args });
    if (this._func) {
      return this._func(...args);
    } else {
      return undefined;
    }
  }
}
