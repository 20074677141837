import logger from '../log';

const log = logger.getLogger(__filename);

/**
 * Take a template string and a hash of flags, replace all \{FLAG\} with it's
 * value.
 *
 * @param template - the string to detemplate.
 * @param flags - object with key value pairs to replace the templatization
 * @returns detemplated string
 */
const detemplate = (
  template: string,
  flags: Record<string, string>
): string => {
  if (typeof template !== 'string') {
    log.info('Implementation error: passed in non string template');
    return template;
  }
  return Object.entries(flags).reduce((acc: string, [key, value]) => {
    let val;
    do {
      val = acc;
      acc = acc.replace(`{${key}}`, value);
    } while (val != acc);
    return acc;
  }, template);
};

export default detemplate;
