import base64ToJson from './base64ToJson';

export default function <T>(jwt: string): T | undefined {
  const tokens = jwt.split('.');
  const payloadString = tokens[1];
  if (!payloadString) {
    return;
  }
  return base64ToJson(payloadString, 'jwt');
}
