/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
const CONSTRUCTOR = 'constructor';
const FUNCTION = 'function';

function allprops(obj: any): Array<any> {
  const props = [];
  do {
    for (const k of Reflect.ownKeys(obj)) {
      props.push([obj, k]);
    }
  } while ((obj = Reflect.getPrototypeOf(obj)) && obj !== Object.prototype);
  return props;
}

/**
 * Pass `this` to autobind all members.
 * @param self - the `this` to bind to
 * @returns - self (this) that is passed
 * @throws when self is undefined or null.
 */
export default function(self: any): any {
  if (!self) {
    throw Error('autobind: "context" argument not specified');
  }
  for (const [obj, k] of allprops(self.constructor.prototype)) {
    if (k === CONSTRUCTOR) {
      continue;
    }
    const pd = Reflect.getOwnPropertyDescriptor(obj, k);
    if (pd && typeof pd.value === FUNCTION) {
      self[k] = self[k].bind(self);
    }
  }
  return self;
}
