import { ReportConfigInterface } from './ReportConfig';

const DEFAULT_MAX_LOGS = 10;
const DEFAULT_MAX_BITRATE_CHANGES = 10;

export default class MaximumReportConfig {
  log: number; // Max number of logs to send.
  bc: number; // Max bitrate changes to send.

  constructor(obj?: MaximumReportConfig, report?: ReportConfigInterface) {
    const item = obj || ({} as MaximumReportConfig);
    const rep = report || ({} as ReportConfigInterface);
    this.log = item.log || DEFAULT_MAX_LOGS;
    this.bc = item.bc || rep.mbc || DEFAULT_MAX_BITRATE_CHANGES;
  }
}
