/* eslint-disable @typescript-eslint/no-explicit-any */
export function shouldRemove(v: unknown, removeEmpty?: boolean): boolean {
  return (
    v == null ||
    typeof v == 'undefined' ||
    (removeEmpty && typeof v == 'string' && v == '') ||
    (Array.isArray(v) && v.length == 0) ||
    (typeof v === 'object' && Object.entries(v).length == 0)
  );
}

/**
 * @param item - the item to copy without any/all recursively empty attributes
 * @returns a copy of the item with all attributes recursivley removed that are
 * either undefined, empty objects, or empty arrays. (this is to help
 * reduce the size of payloads)
 */
export default function cleanse(item: unknown, removeEmpty?: boolean): any {
  if (typeof item == 'undefined') {
    return item;
  }
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const obj: any = item;
  if (Array.isArray(obj)) {
    return obj
      .map(v => (v && typeof v === 'object' ? cleanse(v, removeEmpty) : v))
      .filter(v => !shouldRemove(v, removeEmpty));
  }
  if (obj && typeof obj.toJSON == 'function') {
    // The Webrtc/API objects have a handy toJSON function
    return cleanse(obj.toJSON(), removeEmpty);
  }
  return Object.entries(obj)
    .map(([k, v]) => [
      k,
      v && typeof v === 'object' ? cleanse(v, removeEmpty) : v
    ])
    .reduce(
      (a: any, [k, v]) => (
        shouldRemove(v, removeEmpty) ? a : (a[k.toString()] = v), a
      ),
      {}
    );
}

/* eslint-enable @typescript-eslint/no-explicit-any */
