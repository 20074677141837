import { PrepareConfig } from './MSBootLoader';
import { PlaybackInfo } from './interfaces';
type SorU = string | undefined;

export const getBestEnvSuffix = (possibilities: [string, string][]): string => {
  return (
    possibilities.reduce((acc: SorU, [kollectiveHost, toReplace]) => {
      if (acc) {
        return acc;
      }
      if (kollectiveHost) {
        const url = new URL(kollectiveHost);
        return url.host.replace(toReplace, '');
      }
      return undefined;
    }, undefined) || 'kollective.app'
  );
};

export function getEnvSuffix(
  kollectiveHost: string,
  toReplace: string
): string {
  return getBestEnvSuffix([[kollectiveHost, toReplace]]);
}

export function fromPlaybackInfo(playbackInfo: PlaybackInfo): PrepareConfig {
  const { token, config } = playbackInfo;
  let { tenantId } = config;
  const envHostSuffix = getBestEnvSuffix([
    [config.th, 'tenant.'],
    [config.ch, 'content.']
  ]);
  if (!tenantId && playbackInfo.items[0]) {
    tenantId = playbackInfo.items[0].tenantId;
  }
  const ah = playbackInfo.config.ah;
  return {
    tenantId,
    envHostSuffix,
    token,
    ah
  };
}
