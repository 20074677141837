import setBool from './util/setBool';

export interface WebrtcConfig {
  enableDscpTagging: boolean; // default false
}

export interface MaybeWebrtcConfig {
  enableDscpTagging?: boolean;
}

export default function webrtcConfig(obj?: MaybeWebrtcConfig): WebrtcConfig {
  const enableDscpTagging = setBool(obj && obj.enableDscpTagging, false);
  return {
    enableDscpTagging
  };
}
