import { LogLevel } from './Interfaces';
export const KEY_STORE_LOGGER = 'ksdk.log';

export enum Level {
  TRACE = 'TRACE',
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARN = 'WARN',
  FATAL = 'FATAL',
  OFF = 'OFF'
}

// Use a bitmask to allow multiple types.
export enum LogType {
  BASIC = 0,
  ENHANCED = 1,
  TIMESTAMP = 2,
  DATE = 4,
  LARGE = 8,
  COLOR = 16
}
const makeBitMask = (item: Record<string, string | number>): number => {
  return Object.entries(item).reduce((acc, current) => {
    if (typeof current[1] === 'number') {
      return acc + current[1];
    }
    return acc;
  }, 0);
};
export const LogTypeMask = makeBitMask(LogType);
export const LogTypeFormatMask = LogType.LARGE | LogType.COLOR;
export const LogTypeTimeMask = LogType.TIMESTAMP | LogType.DATE;

export const LogLevels: { [key: string]: LogLevel<Level> } = {
  TRACE: { label: Level.TRACE, value: 6 },
  DEBUG: { label: Level.DEBUG, value: 5 },
  INFO: { label: Level.INFO, value: 4 },
  WARN: { label: Level.WARN, value: 3 },
  ERROR: { label: Level.ERROR, value: 2 },
  FATAL: { label: Level.FATAL, value: 1 },
  OFF: { label: Level.OFF, value: 0 }
};

// Get the one letter shortened forms for configs.
// i.e. T: { label: Level:TRACE, value: 6 }...
export const ShortLogLevels: {
  [key: string]: LogLevel<Level>;
} = Object.entries(LogLevels).reduce((previous, current) => {
  const key: string = current[0];
  const value: LogLevel<Level> = current[1];
  (previous as Record<string, LogLevel<Level>>)[key.substring(0, 1)] = value;
  return previous;
}, {});

export function getLevel(level: Level): LogLevel<Level> {
  return LogLevels[level.toUpperCase()];
}
