enum PlayerType {
  AMP = 'AMP',
  Bitmovin = 'Bitmovin',
  FetchFactoryPlayer = 'FetchFactoryPlayer',
  XHRFactoryPlayer = 'XHRFactoryPlayer',
  Flowplayer = 'Flowplayer',
  HLSjs = 'HLSjs',
  HLSjsConfig = 'HLSjsConfig',
  Shaka = 'Shaka',
  THEOplayer = 'THEOplayer',
  Viblast = 'Viblast',
  VideoJS = 'VideoJS'
}

export default PlayerType;
