import {
  Level,
  LogType,
  LogTypeMask,
  LogTypeTimeMask,
  getLevel
} from './LoggerConstants';
import { AppenderData, LogLevel, LogMessage } from './Interfaces';

const TRACE: LogLevel<Level> = getLevel(Level.TRACE);

export class LogData {
  readonly level: LogLevel<Level>;
  readonly ts: number;
  readonly msg: LogMessage[];
  readonly type: string;

  constructor(
    level: LogLevel<Level>,
    msg: LogMessage[],
    type?: string,
    ts?: number
  ) {
    this.level = level;
    this.msg = msg;
    this.type = type || '';
    this.ts = ts || Date.now();
  }
}

/**
 * Set NAME of subclasses to uppercase.
 */
export default abstract class Appender implements AppenderData {
  enabled: boolean;
  level: LogLevel<Level>;
  name: string;
  logType: LogType;
  levelMap: Record<string, LogLevel<Level>>;
  logTypes: LogType[];
  readonly minLevel: LogLevel<Level>;

  constructor(
    enabled: boolean,
    level: LogLevel<Level>,
    name: string,
    minLevel: LogLevel<Level> = TRACE
  ) {
    if (level.value > minLevel.value) {
      level = minLevel;
    }
    this.enabled = enabled;
    this.level = level;
    this.name = name;
    this.logType = LogType.BASIC;
    this.logTypes = [LogType.BASIC];
    this.levelMap = {};
    this.minLevel = minLevel;
  }

  setLevel(level: LogLevel<Level>, appenderName?: string, type?: string): void {
    if (this.minLevel.value < level.value) {
      level = this.minLevel;
    }
    if (!appenderName || appenderName.toUpperCase() === this.name) {
      if (type) {
        this.levelMap[type] = level;
      } else {
        if (level.value === 0) {
          // Clear all the levels when turned off.
          this.levelMap = {};
        }
        this.level = level;
      }
    }
  }

  shouldHandle(level: LogLevel<Level>, type: string): boolean {
    const typeLevel = this.lookupLevel(type);
    if (this.testLevel(level, typeLevel)) {
      return true;
    } else if (typeLevel) {
      return false;
    }
    return this.testLevel(level, this.level);
  }

  lookupLevel(category: string): LogLevel<Level> | undefined {
    let search = category;
    let old = search;
    do {
      old = search;
      const level = this.levelMap[search];
      if (level !== undefined) {
        return level;
      }
      search = search.replace(/.[^.]*$/, '');
    } while (old != search);
    return;
  }

  testLevel(
    level: LogLevel<Level>,
    localLevel: LogLevel<Level> | undefined
  ): boolean {
    return localLevel ? level.value <= localLevel.value : false;
  }

  message(data: LogData): void {
    if (this.shouldHandle(data.level, data.type)) {
      this.handle(data);
    }
  }

  abstract handle(data: LogData): void;

  setType(type: LogType | string): void {
    const getType = (type: LogType | string): LogType => {
      if (typeof type === 'string') {
        switch (type.toUpperCase()) {
          case 'BASIC':
            return LogType.BASIC;
          case 'ENHANCED':
            return LogType.ENHANCED | this.logType;
          case 'TIMESTAMP':
            return LogType.TIMESTAMP | (this.logType & ~LogTypeTimeMask);
          case 'DATE':
            return LogType.DATE | (this.logType & ~LogTypeTimeMask);
          case 'LARGE':
            return LogType.LARGE | this.logType;
          case 'COLOR':
            return LogType.COLOR | this.logType;
        }
        return this.logType;
      }
      return type & LogTypeMask;
    };
    if (typeof type === 'string') {
      type.split(',').forEach(item => {
        this.logType = getType(item);
      });
    } else {
      this.logType = getType(type);
    }
  }

  // Serializing to json should only require the base interface
  toJSON(): AppenderData {
    const { enabled, level, name } = this;
    return {
      enabled,
      level,
      name
    };
  }
}
