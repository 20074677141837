import LegacyContentConfig from './LegacyContentConfig';
import setBool from './util/setBool';
import setVar from './util/setVar';
import CacheConfig, { MaybeCacheConfig } from './CacheConfig';
import webrtcConfig, { MaybeWebrtcConfig, WebrtcConfig } from './WebrtcConfig';
import nodeConfig, { MaybeNodeConfig, NodeConfig } from './NodeConfig';

export interface MaybePeeringConfig {
  version?: number;
  peeringDisabled?: boolean;
  // Peering v2 configurations
  pullThroughCache?: boolean; // Use pull-through caching [Default false]
  proxyIngest?: boolean; // Whether Nodes should ingest content on behalf of a peer [Default false]
  peerFetchTimeout?: number; // Timeout (in ms) for sourcing content from peer, will fall back to ingest [Default 5 seconds]
  maxClusterDepth?: number; // Maximum depth of a cluster [Default 1]
  peerLimit?: number; // Limits the number of connections [Default 15]
  maxAttachmentRetries?: number; // How many times to retry attachment [Default 4]

  // Used for debugging
  wasmUrl?: string; // (v11*) required for all if not using a published version of the ksdk
  wasmDebug?: boolean;

  // Cache Configuration
  cache?: MaybeCacheConfig;

  // webrtc specific config
  webrtc?: MaybeWebrtcConfig;
  node?: MaybeNodeConfig;
}

export default class PeeringConfig {
  version: number;
  peeringDisabled: boolean;

  // Peering v2 configurations
  peerLimit: number; // Limits the number of connections [Default 15]
  maxAttachmentRetries: number; // How many times to retry attachment [Default 4]

  // Used for debugging
  wasmUrl?: string; // (v11*) required for all if not using a published version of the ksdk
  wasmDebug: boolean;

  // Cache Configuration
  cache: CacheConfig;

  // webrtc specific config
  webrtc: WebrtcConfig;
  node: NodeConfig;

  constructor(obj?: MaybePeeringConfig, config?: LegacyContentConfig) {
    this.version = setVar(
      1,
      obj && obj.version,
      config && config.peeringVersion
    );
    this.peeringDisabled = obj?.peeringDisabled ?? false;
    this.maxAttachmentRetries = setVar(4, obj && obj.maxAttachmentRetries);
    this.peerLimit = setVar(15, obj && obj.peerLimit);
    this.wasmDebug = setVar(
      false,
      obj && obj.wasmDebug,
      config && config.wasmDebug
    );
    this.wasmUrl = (obj && obj.wasmUrl) || (config && config.wasmUrl);
    this.cache = new CacheConfig(obj && obj.cache);
    this.webrtc = webrtcConfig(obj && obj.webrtc);

    const shouldPullThrough = setBool(obj && obj.pullThroughCache, false);
    // for the new node config, try the inbound node config. Otherwise, use the legacy values.
    const node = (obj && obj.node) || {
      peerFetchTimeout: setVar(5000, obj && obj.peerFetchTimeout),
      shouldPullThrough,
      proxyIngest: setBool(obj && obj.proxyIngest, false),
      // SRV-1797 - max cluster depth is based on pullThroughCache.
      // if pullThroughCache is true, then depth is 2, else 1,
      maxClusterDepth: shouldPullThrough ? 2 : 1
    };
    this.node = nodeConfig(node);
  }
}
