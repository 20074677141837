import { KEY_STORE_LOGGER, Level } from './LoggerConstants';
import { AppenderData, LogLevel } from './Interfaces';
import { SafeGetArgs, safeGet, safeSave } from '../util/storage';

interface LoggerInfo {
  id?: string;
  rootLevel?: LogLevel<Level>;
  appenderData?: AppenderData[];
}

const baseArgs: SafeGetArgs<LoggerInfo> = {
  key: KEY_STORE_LOGGER,
  default: undefined as unknown as LoggerInfo,
  which: 'sessionStorage'
};

export function getStoredLoggerInfo(): LoggerInfo {
  return safeGet(baseArgs) as LoggerInfo;
}

export function saveStoredLoggerInfo(data: LoggerInfo): void {
  safeSave({ ...baseArgs, data });
}
