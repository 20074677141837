import { isString } from '../../common/utils/stringify';

/**
 * Provides a default fro expected inbound Integer values.
 * This function will treat 0 as a valid value, whereas it might be overwritten in common boolean logic.
 */
export default function setInt(
  value: string | number | undefined | null,
  fallback: number
): number {
  if (value === 0 || value === '0') {
    return 0;
  }
  if (!value) {
    return fallback;
  }
  if (isString(value)) {
    const out = parseInt(value);
    if (isNaN(out)) {
      return fallback;
    }
    return out;
  }
  return value;
}
