import { PlayType } from '@kollective-frontend/ksdk-api';
import logger from '../log';

const log = logger.getLogger(__filename);

export enum ProxyType {
  EDGE_CACHE = 'EdgeCache'
}

export enum MediaType {
  MANIFEST = 'MANIFEST',
  SEGMENT = 'SEGMENT'
}

export interface CacheDuration {
  mediaType: MediaType;
  playType: PlayType;
  duration: number; // in seconds
}

export interface ProxyConfig {
  // This value describes the type of proxy and allows the sdk to dispatch to the appropriate implementation.
  proxyType: ProxyType;
  // allows for disabling a config like is done with agents.
  disabled: boolean;
  // hostname for proxy service
  host: string;
  // the scheme prefix for requests. Expected values at this time are http and https
  scheme: string;
  // the timeout expected for requests to the proxy. This is the total time the sdk should wait for payloads.
  timeout: number;
  cacheDurations: CacheDuration[];
}

export interface MaybeProxyConfig {
  // This value describes the type of proxy and allows the sdk to dispatch to the appropriate implementation.
  proxyType?: string;
  // allows for disabling a config like is done with agents.
  disabled?: boolean;
  // hostname for proxy service
  host?: string;
  // the scheme prefix for requests. Expected values at this time are http and https
  scheme?: string;
  // the timeout expected for requests to the proxy. This is the total time the sdk should wait for payloads.
  timeout?: number;
  // the relative cache durations for different play and media types
  cacheDurations?: CacheDuration[];
}

export default function proxyConfig(
  proxy?: MaybeProxyConfig
): ProxyConfig | undefined {
  if (
    !proxy ||
    !proxy.proxyType ||
    !proxy.host ||
    !proxy.scheme ||
    !proxy.cacheDurations
  ) {
    return undefined;
  }
  if (proxy.proxyType !== ProxyType.EDGE_CACHE) {
    log.error(
      `Unknown proxy config type: ${proxy.proxyType}. Dropping proxy config.`
    );
    return undefined;
  }
  const p = {
    // casting the string to the string tells tsc that we want it to be of the expected type,
    // not just a string. However, using the expected type union would convert any string to the wrong type.
    proxyType: ProxyType.EDGE_CACHE,
    disabled: proxy.disabled || false,
    timeout: proxy.timeout || 3,
    host: proxy.host,
    scheme: proxy.scheme,
    cacheDurations: proxy.cacheDurations
  };

  return p;
}
