import setBool from './util/setBool';
import setInt from './util/setInt';

export interface MaybePeerCapacity {
  up: number;
  mid: number;
  down: number;
}
export interface PeerCapacity {
  up: number;
  mid: number;
  down: number;
}

export function peerCapacity(
  capacity: MaybePeerCapacity | undefined,
  defaults: PeerCapacity
): PeerCapacity {
  if (!capacity) {
    return defaults;
  }
  return {
    up: setInt(capacity.up, defaults.up),
    mid: setInt(capacity.mid, defaults.mid),
    down: setInt(capacity.down, defaults.down)
  };
}

export interface MaybeNodeConfig {
  attachmentInterval?: number;
  backoffInterval?: number;
  contentInterval?: number;
  coordinatorInterval?: number;
  maxClusterDepth?: number;
  maxPeerAttempts?: number;
  maxPeerCapacity?: PeerCapacity;
  maxSegmentSize?: number;
  peerFetchTimeout?: number;
  proxyIngest?: boolean;
  reservedPeerCapacity?: PeerCapacity;
  shouldBackoff?: boolean;
  shouldPullThrough?: boolean;
}

export interface NodeConfig {
  attachmentInterval: number;
  backoffInterval: number;
  contentInterval: number;
  coordinatorInterval: number;
  maxClusterDepth: number;
  maxPeerAttempts: number;
  maxPeerCapacity: PeerCapacity;
  maxSegmentSize: number;
  peerFetchTimeout: number;
  proxyIngest: boolean;
  reservedPeerCapacity: PeerCapacity;
  shouldBackoff: boolean;
  shouldPullThrough: boolean;
}

export default function nodeConfig(obj?: MaybeNodeConfig): NodeConfig {
  obj = obj || {};
  return {
    attachmentInterval: setInt(obj.attachmentInterval, 7000),
    backoffInterval: setInt(obj.backoffInterval, 500),
    contentInterval: setInt(obj.contentInterval, 3000),
    coordinatorInterval: setInt(obj.coordinatorInterval, 1500),
    maxClusterDepth: setInt(obj.maxClusterDepth, 1),
    maxPeerAttempts: setInt(obj.maxPeerAttempts, 0),
    maxPeerCapacity: peerCapacity(obj.maxPeerCapacity, {
      up: 0,
      mid: 15,
      down: 0
    }),
    maxSegmentSize: setInt(obj.maxSegmentSize, 0),
    peerFetchTimeout: setInt(obj.peerFetchTimeout, 5000),
    proxyIngest: setBool(obj.proxyIngest, false),
    reservedPeerCapacity: peerCapacity(obj.reservedPeerCapacity, {
      up: 0,
      mid: 2,
      down: 0
    }),
    shouldBackoff: setBool(obj.shouldBackoff, false),
    shouldPullThrough: setBool(obj.shouldPullThrough, false)
  };
}
