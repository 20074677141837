/**
 * Replace occurrences of strings wrapped in \{\} where the inside is the string to replace.
 * @example
 * ```typescript
 * replaceTemplateString('{test}/{other}', { test: 'value', other: 'thing' }) === 'value/thing'
 * ```
 *
 * @param toModify - the string containing templates.
 * @param params - the record containing strings to lookup mapped to the value that should be subsituted.
 * @returns a string where params have been replaced by the corresponding value.
 */
export default function replaceTemplateString(
  toModify: string,
  params: Record<string, string>
): string {
  return Object.keys(params).reduce(
    (acc, name) => acc.replace(`{${name}}`, params[name]),
    toModify
  );
}
