import SDKConfig from '../SDKConfig';
import { mergeUserAgentHeaders } from '../../util/getUserAgentHeaders';
import getAgentId from '../../util/getAgentId';

export interface HeadersArgs {
  contentId?: string;
  playGuid?: string;
  sdkConfig?: SDKConfig;
  tenantId?: string;
  token?: string;
  noUAHeaders?: boolean;
}

type RSS = Record<string, string>;
type SorU = string | undefined;

const addHeaderIfNotNull = (
  headers: RSS,
  name: string,
  value: SorU,
  avoid?: string
): void => {
  if (!value || value === avoid) {
    return;
  }
  headers[`X-Kollective-${name}`] = value;
};

const getKollectiveHeaders = (obj: HeadersArgs): Record<string, string> => {
  const headers: Record<string, string> = {};
  if (!obj) {
    return headers;
  }
  if (!obj.noUAHeaders) {
    mergeUserAgentHeaders(headers);
  }
  addHeaderIfNotNull(headers, 'ContentId', obj.contentId);
  addHeaderIfNotNull(headers, 'PlayGuid', obj.playGuid);
  addHeaderIfNotNull(headers, 'TenantId', obj.tenantId);
  if (obj.sdkConfig) {
    const appName = obj.sdkConfig.integrationName();
    addHeaderIfNotNull(headers, 'AppName', appName, 'ksdk');
    addHeaderIfNotNull(headers, 'KSDKVersion', obj.sdkConfig.version);
  }
  if (Object.keys(headers).length > 0) {
    // Sometimes we only need the Authorization, so we only add the AgentId
    // header if we have at least one header by this point.
    addHeaderIfNotNull(headers, 'AgentId', getAgentId());
  }
  if (obj.token) {
    headers.Authorization = `Bearer ${obj.token}`;
  }
  return headers;
};

export default getKollectiveHeaders;
