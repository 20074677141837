import { Plugin, KollectiveGlobalApi } from '../common/interfaces';

const getPlugin = (): Plugin | undefined => {
  if (!window.ksdk) {
    return undefined;
  }
  const ksdk = window.ksdk as KollectiveGlobalApi;
  return ksdk._internal?.plugin;
};

export default getPlugin;
