import { Level, LogType, LogTypeFormatMask } from './LoggerConstants';
import { LogLevel, LogMessage } from './Interfaces';
import Appender, { LogData } from './Appender';

interface FunctionLookupTable {
  [name: string]: (...args: LogMessage[]) => void;
}

const bindings: FunctionLookupTable = {
  // eslint-disable-next-line no-console
  ERROR: console.error.bind(console),
  // eslint-disable-next-line no-console
  WARN: console.warn.bind(console),
  // eslint-disable-next-line no-console
  INFO: console.info.bind(console),
  // eslint-disable-next-line no-console
  DEBUG: console.debug.bind(console),
  // eslint-disable-next-line no-console
  TRACE: console.trace.bind(console),
  // eslint-disable-next-line no-console
  FATAL: console.error.bind(console),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  OFF: () => {}
};

const fixedWidth = (v: number, width: number, suffix?: string): string => {
  let out = v.toString();
  suffix = suffix || '';
  if (width < 1 || width > 10) {
    return out;
  }
  while (out.length < width) {
    out = `0${out}`;
  }
  return out + suffix;
};

export const formatDate = (ts: number, logType: LogType): string | number => {
  if (logType & LogType.TIMESTAMP) {
    return `:${ts}`;
  }
  if (logType & LogType.DATE) {
    const d = new Date(ts);
    return (
      ':' +
      fixedWidth(d.getMonth() + 1, 2, '-') +
      fixedWidth(d.getDate(), 2, 'T') +
      fixedWidth(d.getHours(), 2, ':') +
      fixedWidth(d.getMinutes(), 2, ':') +
      fixedWidth(d.getSeconds(), 2, '.') +
      fixedWidth(d.getMilliseconds(), 3)
    );
  }
  return '';
};

const colorScheme = (item: LogData): string | undefined => {
  switch (item.level.label) {
    case 'TRACE':
      return '';
    case 'DEBUG':
      return 'color: #09f';
    case 'INFO':
      return 'color: green';
    case 'WARN':
      return 'color: #f9f';
    case 'ERROR':
      return 'color: red';
    case 'FATAL':
      return 'color: white;background-color: red';
  }
  return '';
};

export default class ConsoleAppender extends Appender {
  static NAME = 'CONSOLE';

  constructor(enabled: boolean, level: LogLevel<Level>) {
    super(enabled, level, ConsoleAppender.NAME);
    this.logType = LogType.DATE;
    this.logTypes.push(LogType.ENHANCED);
    this.logTypes.push(LogType.TIMESTAMP);
  }

  handle(data: LogData): void {
    const ts: number | string = formatDate(data.ts, this.logType);
    const type = data.type ? ` ${data.type}` : '';
    const fkey = this.logType & LogTypeFormatMask ? '%c' : '';
    const msg = [`${fkey}${data.level.label}${ts}${type}`];
    if (fkey) {
      const format = [];
      if (this.logType & LogType.COLOR) {
        const color = colorScheme(data);
        if (color) {
          format.push(color);
        }
      }
      if (this.logType & LogType.LARGE) {
        format.push('font-size: 1.4em');
      }
      msg.push(format.join(';'));
    }
    if (this.logType & LogType.ENHANCED) {
      const func = bindings[data.level.label];
      func(...msg, ...data.msg);
    } else {
      // eslint-disable-next-line no-console
      console.log(...msg, ...data.msg);
    }
  }
}
